import Popover from '@components/popover';
import Content, { Item } from '@components/popover/components/content';
import { useStore } from '@hooks/useStore';
import { Header } from 'antd/es/layout/layout';
import cn from 'classnames';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { addZero } from 'utils/addZero';

import AddCashboxReportModal from '../add-report';
import ModalBreak from '../modal-break';
import ModalBreakConfirm from '../modal-break-confirm';
import ModalCloseShift from '../modal-close-shift';
import ModalExit from '../modal-exit';
import QrContent from '../qr-content';
import styles from './index.module.scss';

let id: ReturnType<typeof setInterval>;

const MainHeader: FC = () => {
	const store = useStore();
	const [logoutConfirm, setLogoutConfirm] = useState(false);
	const [openCloseShift, setOpenCloseShift] = useState(false);
	const [breakTime, setBreakTime] = useState('');

	useEffect(() => {
		clearInterval(id);

		if (store.shift?.break?.type === 1) {
			const startTime = dayjs(store.shift.break.opened_at);
			const endTime = startTime.add(store.shift.break.duration || 0, 'minute');

			id = setInterval(() => {
				const currentTime = dayjs();
				const diff = endTime.diff(currentTime, 'second');
				if (diff < 0) {
					store.closeBreak();
				} else {
					setBreakTime(`${addZero(Math.floor(diff / 60))}:${addZero(diff % 60)}`);
				}
			}, 1000);
		}
		return () => clearInterval(id);
	}, [store.shift?.break]);

	return (
		<Header className={styles.header}>
			{store.operator && (
				<p className={styles.header__info}>
					{store.operator.store.id} {store.operator.store.name}
				</p>
			)}
			{store.shift?.break?.type === 1 && <p className={styles.header__break}>До окончания перерыва: {breakTime}</p>}
			<Popover
				content={
					<>
						<Content
							items={[
								...((!store.shift
									? [
											{ text: 'Открыть смену', iconName: 'Sun', onClick: store.openShift },
											{ text: 'Выйти', onClick: setLogoutConfirm.bind(null, true) },
									  ]
									: [
											{
												text: store.shift?.break?.type ? 'Завершить перерыв' : 'Перерыв',
												iconName: 'Pause',
												onClick: store.shift?.break?.type ? store.closeBreak : store.showBreakConfirm.bind(null, true),
											},
											{ text: 'Закрыть смену', iconName: 'Moon', onClick: setOpenCloseShift.bind(null, true) },
									  ]) as Item[]),
								{
									text: 'Настройки принтера',
									iconName: 'Settings',
									onClick: store.print.openPrinterSettings,
								},
								// ...(store.operator?.store.access_settings.routing === 1
								// 	? ([{ text: 'Отпустить водителя', onClick: store.setDriverModal.bind(null, true) }] as Item[])
								// 	: []),
							]}
						/>
						{store?.shift && <QrContent />}
					</>
				}
			>
				<span
					className={cn(styles.account, {
						[styles.account_break]: store.shift?.status === 1,
						[styles.account_closed]: !store.shift,
					})}
				>
					{store.user?.name}
				</span>
			</Popover>
			<ModalExit onConfirm={store.logout} open={logoutConfirm} onCancel={setLogoutConfirm.bind(null, false)} />
			<ModalBreak open={store.shift?.break?.type === 2} />
			<ModalCloseShift start="" end="" open={openCloseShift} onClose={setOpenCloseShift.bind(null, false)} />
			<ModalBreakConfirm />
			<AddCashboxReportModal />
		</Header>
	);
};

export default observer(MainHeader);
