/* eslint-disable @typescript-eslint/no-empty-interface */
import { AcceptanceService } from '@services/acceptance';
import { RootStore } from '@store/index';
import { clone, flow, getRoot, SnapshotIn, types } from 'mobx-state-tree';

const { string, optional, number, union, literal, model, maybeNull, array } = types;

const PostalItemTrouble = model('postal-items-trouble', {
	id: number,
	number: string,
	status: maybeNull(number),
	sender_full_name: maybeNull(string),
	receiver_full_name: maybeNull(string),
	width: maybeNull(number),
	height: maybeNull(number),
	length: maybeNull(number),
	weight: maybeNull(number),
	is_reported: optional(union(literal(0), literal(1)), 0),
});

const PostalTrouble = model('postal-trouble', {
	postalItems: maybeNull(PostalItemTrouble),
	unidentifiedItems: maybeNull(PostalItemTrouble),
	dataTableItemTrouble: maybeNull(array(PostalItemTrouble)),
	dataModalProblem: maybeNull(PostalItemTrouble),
}).actions((self) => {
	const root = getRoot<typeof RootStore>(self);

	return {
		loadPostalTroubleSpaces: flow(function* () {
			root.setLoading(true);
			self.dataTableItemTrouble = null;

			try {
				const res = yield AcceptanceService.getPostalTroubleItems();
				self.dataTableItemTrouble = res.data.postalItems.concat(res.data.unidentifiedItems);
			} catch (err: any) {
				root.notice.setNotice({ message: err?.error || err?.message || '' });
			} finally {
				root.setLoading(false);
			}
		}),
		loadDataModalProblem: (id: number) => {
			const data = self.dataTableItemTrouble?.find((el) => el.id === id);
			if (data) {
				self.dataModalProblem = PostalItemTrouble.create(clone(data));
			}
		},
		closeModalItemProblem() {
			self.dataModalProblem = null;
		},
	};
});

export default PostalTrouble;

export interface IPostalTroubleItem extends SnapshotIn<typeof PostalItemTrouble> {}
