/* eslint-disable @typescript-eslint/no-empty-interface */
import { AcceptanceService } from '@services/acceptance';
import { ShipmentService } from '@services/shipment';
import { cast, destroy, flow, getRoot, SnapshotIn, types } from 'mobx-state-tree';
import { voiceMessage } from 'utils/voiceMessage';

import { RootStore } from '..';

const { string, optional, number, union, literal, model, maybeNull, array } = types;

const Order = model('order', {
	id: number,
	order_market_id: number,
	order_status: number,
	receiver_fio: string,
	routing_items_count: number,
	bags: maybeNull(
		array(
			model('bags', {
				name: string,
				is_scanned: optional(union(literal(0), literal(1)), 0),
			})
		)
	),
});

const Item = model('items', {
	id: number,
	name: '',
	order_market_id: number,
	is_scanned: optional(union(literal(0), literal(1)), 0),
	isNewError: false,
	key: maybeNull(number),
});

const ItemsModal = model('modal-items', {
	routing_items: maybeNull(array(Item)),
	scanned_count: number,
	count: number,
})
	.actions((self) => {
		return {
			scanBarcode: flow(function* (barcode: string) {
				const root = getRoot<typeof RootStore>(self);

				if (!self.routing_items) {
					root.notice.setNotice({ message: 'Нет пакетов' });
					return;
				}

				root.setLoading(true);

				try {
					const item = self.routing_items.find(({ name }) => name === barcode);

					if (item) {
						yield ShipmentService.putItem(item.id, { is_scanned: 1 });
						root.acceptance.emall.loadItems();
					} else {
						const res = yield AcceptanceService.getEmallItemName(barcode);
						self.routing_items.unshift({ ...res.data, isNewError: true, key: res.data.id });
					}
				} catch (err: any) {
					root.notice.setNotice({ message: err?.error || err?.message || '' });
					throw err?.error || err?.message || 'Ошибка';
				} finally {
					root.setLoading(false);
				}
			}),
			deleteItem: flow(function* (item: IItem) {
				const root = getRoot<typeof RootStore>(self);

				if (!self.routing_items) {
					root.notice.setNotice({ message: 'Нет пакетов' });
					return;
				}

				root.setLoading(true);

				try {
					if (item.isNewError) {
						self.routing_items = cast(self.routing_items.filter(({ id }) => id !== item.id));
					} else {
						yield ShipmentService.putItem(item.id, { is_scanned: 0 });
						root.acceptance.emall.loadItems();
					}
				} catch (err: any) {
					root.notice.setNotice({ message: err?.error || err?.message || '' });
				} finally {
					root.setLoading(false);
				}
			}),
		};
	})
	.views((self) => ({
		get errorCount() {
			return self.routing_items?.filter(({ isNewError }) => isNewError).length;
		},
		get hasItems() {
			return self.routing_items && self.routing_items?.length > 0;
		},
		get hasUnscanned() {
			return self.routing_items?.some(({ is_scanned }) => is_scanned === 0);
		},
	}));

const Product = model('product', {
	id: number,
	item_market_id: number,
	name: string,
	order_market_id: number,
	order_item_id: maybeNull(number),
});

const ProductInfoModal = model('modal-info', {
	show: false,
	store_place_item_id: maybeNull(number),
	store_place_item_full_name: maybeNull(string),
	store_place_item_text: maybeNull(string),
	item: Product,
});

const ItemsProductModal = model('modal-items-product', {
	items: maybeNull(array(Product)),
	description: '',
	spaceId: maybeNull(number),
	type: maybeNull(union(literal(1), literal(2))),
	infoModal: maybeNull(ProductInfoModal),
	isNewCell: false,
})
	.actions((self) => {
		return {
			setIsNewCell(value: boolean) {
				self.isNewCell = value;
			},
			scanPlaceItemBarcode: flow(function* (barcode: number) {
				if (!self.infoModal || !self.infoModal.item.order_item_id) return;
				if (self.infoModal.store_place_item_id && self.infoModal.store_place_item_id !== barcode && !self.isNewCell) {
					localStorage.setItem('voiceMessage', 'Неверная ячейка');
					voiceMessage();
					return;
				}

				const root = getRoot<typeof RootStore>(self);
				root.setLoading(true);

				const placeId: number | null = self.infoModal.store_place_item_id
					? self.isNewCell
						? self.infoModal.store_place_item_id
						: null
					: null;

				try {
					yield AcceptanceService.postEmallPlaceItemScan({
						store_place_item_id_to_block: placeId,
						store_place_item_id_to_choose: barcode,
						order_item_id: self.infoModal.item.order_item_id,
						order_market_id: self.infoModal.item.order_market_id,
					});

					self.isNewCell = false;
					root.acceptance.emall.modalItemsProduct?.closeInfoModal();
				} catch (err: any) {
					root.notice.setNotice({ message: err?.error || err?.message || '' });
				} finally {
					root.setLoading(false);
				}
			}),
			scanItemBarcode: flow(function* (barcode: number) {
				const root = getRoot<typeof RootStore>(self);
				root.setLoading(true);

				try {
					if (!self.spaceId) throw 'Нет идентификатора грузоместа';

					const { data } = yield AcceptanceService.postEmallItemScan({ barcode, routing_space_id: self.spaceId });
					self.infoModal = ProductInfoModal.create({ show: true, ...data });

					localStorage.setItem('voiceMessage', data.store_place_item_text);
					voiceMessage();
				} catch (err: any) {
					root.notice.setNotice({ message: err?.error || err?.message || '' });
				} finally {
					root.setLoading(false);
				}
			}),
			scanBarcode: flow(function* (barcode: string) {
				const root = getRoot<typeof RootStore>(self);

				if (self.type === 1) {
					root.acceptance.emall.modalItemsProduct?.scanItemBarcode(+barcode);
					return;
				}

				root.setLoading(true);

				try {
					const {
						data: { type, ...data },
					} = yield AcceptanceService.postEmallScan(+barcode);

					self.type = type || null;

					if (type === 1) {
						self.description = `Ящик ${data.space.name}`;
						self.items = null;
						self.spaceId = data.space.id;
					}

					if (type === 2) {
						self.infoModal = ProductInfoModal.create({ show: true, ...data });
						localStorage.setItem('voiceMessage', data.store_place_item_text);
						voiceMessage();
					}
				} catch (err: any) {
					root.notice.setNotice({ message: err?.error || err?.message || '' });
				} finally {
					root.setLoading(false);
				}
			}),
			closeInfoModal() {
				const root = getRoot<typeof RootStore>(self);
				root.acceptance.emall.loadItems();
				self.infoModal = null;
			},
			confirm: flow(function* () {
				const root = getRoot<typeof RootStore>(self);

				root.setLoading(true);

				try {
					yield AcceptanceService.postSpacesCloseByProduct(
						self.spaceId ? { space_id: self.spaceId } : { item_ids: self.items?.map(({ id }) => id) }
					);

					root.acceptance.emall.closeModalItems();
				} catch (err: any) {
					root.notice.setNotice({ message: err?.error || err?.message || '' });
				} finally {
					root.setLoading(false);
				}
			}),
		};
	})
	.views((self) => ({
		get hasItems() {
			return self.items && self.items.length > 0;
		},
	}));

const Emall = model('emall', {
	tab: '',
	orders: maybeNull(array(Order)),
	modalItems: maybeNull(ItemsModal),
	modalItemsProduct: maybeNull(ItemsProductModal),
}).actions((self) => {
	const root = getRoot<typeof RootStore>(self);
	return {
		setTab(tab: 'bag' | 'product') {
			self.tab = tab;
		},
		loadOrders: flow(function* () {
			root.setLoading(true);
			try {
				const res = yield AcceptanceService.getEmallOrders();
				self.orders = res.data || null;
			} catch (err: any) {
				root.notice.setNotice({ message: err?.error || err?.message || '' });
			} finally {
				root.setLoading(false);
			}
		}),
		loadItems: flow(function* () {
			root.setLoading(true);
			try {
				if (self.tab === 'product') {
					const { data } = yield AcceptanceService.getEmallItemsProduct();
					self.modalItemsProduct = ItemsProductModal.create({ items: data });
				} else {
					const res = yield AcceptanceService.getEmallItems();
					const addedItems = self.modalItems?.routing_items?.filter(({ isNewError }) => isNewError) || [];
					const items = [...addedItems, ...res.data.routing_items];

					self.modalItems = self.modalItems ? { ...res.data, routing_items: cast(items) } : ItemsModal.create(res.data);
				}
			} catch (err: any) {
				root.notice.setNotice({ message: err?.error || err?.message || '' });
			} finally {
				root.setLoading(false);
			}
		}),
		closeModalItems() {
			self.modalItems = null;
			self.modalItemsProduct = null;
			this.loadOrders();
		},
		hideOrder(order: IOrder) {
			destroy(order);
		},
		handleScanner(evt: KeyboardEvent) {
			root.scanner.handleScanner(evt, (value) => {
				if (value === 'RepeatVoiceMessage') return voiceMessage();
				if (value === 'NewCell') {
					self.modalItemsProduct?.setIsNewCell(true);
					return;
				}

				if (self.modalItemsProduct?.infoModal?.show) {
					self.modalItemsProduct?.scanPlaceItemBarcode(+value);
					return;
				}

				self.modalItems?.scanBarcode(value).catch((err) => console.log('emall-bag/scanner scanBarcode err:', err));

				self.modalItemsProduct
					?.scanBarcode(value)
					.catch((err) => console.log('emall-product/scanner scanBarcode err:', err));
			});
		},
	};
});

export default Emall;

export interface IItemsModal extends SnapshotIn<typeof ItemsModal> {}
export interface IItem extends SnapshotIn<typeof Item> {}
export interface IOrder extends SnapshotIn<typeof Order> {}
export interface IProduct extends SnapshotIn<typeof Product> {}
