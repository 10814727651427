import Input from '@components/input';
import { useStore } from '@hooks/useStore';
import { Button, Modal } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';

import styles from './index.module.scss';

const ModalUnidentifiedCargo: FC = () => {
	const [trackNumberModalEvent, setTrackNumberModal] = useState<string>('');
	const [isOpenTrackNumberModal, setIsOpenTrackNumberModal] = useState(false);

	const {
		acceptance: { postal },
	} = useStore();

	return (
		<>
			<Modal
				footer={
					<div className={styles.footer}>
						<Button onClick={() => postal.setIsShowModalUnidentifiedCargo(false)}>Закрыть</Button>
					</div>
				}
				className={styles.modal}
				title={
					<div className={styles.header}>
						<div>Поиск неопознанного грузоместа</div>
						<div
							className={cn(styles.title, {
								[styles.title__green]: postal.unidentifiedCargoNumbers && postal.unidentifiedCargoNumbers.length >= 2,
							})}
						>
							{!postal.unidentifiedCargoNumbers || postal.unidentifiedCargoNumbers?.length < 2
								? 'Отсканируйте минимум две посылки из вашего мешка'
								: 'Продолжите сканирование посылок из мешка'}
						</div>
					</div>
				}
				width={720}
				open={postal.isShowModalUnidentifiedCargo}
				centered
				onCancel={() => {
					postal.setIsShowModalUnidentifiedCargo(false);
				}}
			>
				<div className={styles.body}>
					<Button className={styles.button} type="text" onClick={() => setIsOpenTrackNumberModal(true)}>
						Ввести штрихкод вручную
					</Button>
					<div>Номер посылки</div>
					{postal.unidentifiedCargoNumbers && (
						<div className={styles.list}>
							{postal.unidentifiedCargoNumbers?.map((el, i) => (
								<span key={i}>{el}</span>
							))}
						</div>
					)}
				</div>
			</Modal>
			<Modal
				open={isOpenTrackNumberModal}
				onCancel={() => setIsOpenTrackNumberModal(false)}
				footer={false}
				title={false}
				destroyOnClose
				centered
				closeIcon={false}
				width={400}
				className={styles.modal__manual}
			>
				<div>
					<div>Введите шрихкод</div>
					<Input onChange={(e) => setTrackNumberModal(e.target.value)} className={styles.input_manual} />
					<Button
						onClick={() => {
							postal.scanBarcode(trackNumberModalEvent);
							setIsOpenTrackNumberModal(false);
						}}
						className={styles.button__manual}
						type="primary"
					>
						Добавить
					</Button>
				</div>
			</Modal>
		</>
	);
};

export default observer(ModalUnidentifiedCargo);
