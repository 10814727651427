import { useStore } from '@hooks/useStore';
import Icon, { ImageName } from '@images/index';
import { IItemPostal } from '@store/acceptance/postal/cargo-spaces';
import { Tooltip } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import styles from './index.module.scss';

const PostalTable: FC = () => {
	const {
		acceptance: { postal },
	} = useStore();

	const columns: ColumnsType<IItemPostal> = [
		{
			title: 'Грузоместо',
			dataIndex: 'cargo-space',
			render: (_, { status_name, status }) => (
				<div className={styles.status__space}>
					<Tooltip title={status_name}>
						<Icon
							className={cn({
								[styles.icon__color]: ['PlayCircle', 'PauseBgTransparent'].includes(
									postal.postalCargoSpaces?.getIconName(status) as ImageName
								),
							})}
							imageName={postal.postalCargoSpaces?.getIconName(status) as ImageName}
						/>
					</Tooltip>
					<span>Мешок</span>
				</div>
			),
			width: 250,
		},
		{
			title: 'Номер пломбы',
			dataIndex: 'seal_number',
			render: (_, { number }) => <span>{number}</span>,
			width: 250,
		},
		{
			title: 'Откуда',
			render: (_, { store_external_name }) => <span>{store_external_name}</span>,
			width: 250,
		},
		{
			title: 'Дата прибытия на ОПС',
			render: (_, { acceptance_date }) => <span>{acceptance_date}</span>,
			width: 250,
		},
		{
			title: 'Количество посылок',
			render: (_, { routing_items_count }) => <span>{routing_items_count}</span>,
			width: 250,
		},
	];

	return (
		<Table
			pagination={false}
			columns={columns}
			dataSource={
				postal.postalCargoSpaces?.postalCargoSpacesItems?.routing_spaces
					? postal.postalCargoSpaces?.postalCargoSpacesItems?.routing_spaces.map((item) => ({ ...item, key: item.id }))
					: []
			}
			onRow={({ status, id }) => ({
				className: cn(styles.row, { [styles.row_pointer]: [80, 81, 82].includes(status) }),
				onClick: () => postal.postalCargoSpaces?.loadItems(id, status),
			})}
			className={styles.table}
			onHeaderRow={() => ({ className: cn(styles.row, styles.row__head) })}
		/>
	);
};

export default observer(PostalTable);
