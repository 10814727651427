interface Win extends Window {
	blobMap?: Map<string, any>;
}

const win: Win = window as any;

//eslint-disable-next-line
export const saveGlobalBlob = (id: string, data: any): void => {
	if (!win.blobMap) {
		win.blobMap = new Map<string, any>();
	}
	win.blobMap.set(id, data);
};

export const getGlobalBlob = (id: string): any => {
	return win.blobMap?.get(id);
};

export const deleteFromGlobalBlob = (id: string): void => {
	if (win.blobMap) {
		win.blobMap.delete(id);
	}
};
