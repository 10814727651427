import Icon from '@images/index';
import cn from 'classnames';
import { FC } from 'react';

import styles from './index.module.scss';

const NoData: FC<{ text?: string; className?: string }> = ({ text = 'Пусто', className = '' }) => (
	<div className={cn(styles.wrap, className)}>
		<Icon imageName="NoTasks" fontSize={100} />
		<p className={styles.text}>{text}</p>
	</div>
);

export default NoData;
