/* eslint-disable @typescript-eslint/no-empty-interface */
import { SnapshotIn, types } from 'mobx-state-tree';

const { model, string, number, union, literal, maybeNull } = types;

const Postal = model('postal', {
	id: number,
	item_id: number,
	weight: number,
	number: string,
	arrival_at: string,
	reason: number,
	reason_name: string,
	wrong_status_type: maybeNull(union(literal(1), literal(2))),
	routing_space_id: maybeNull(number),
	is_scanned: maybeNull(union(literal(0), literal(1))),
	isSurplus: false,
})
	.actions((self) => ({
		update(postal: Partial<IPostal>) {
			Object.keys(postal).forEach((key) => {
				if (key in self) {
					//@ts-ignore
					self[key] = postal[key];
				}
			});
		},
	}))
	.views((self) => ({
		get currentStatus() {
			if (self.wrong_status_type === 1) return 'Неверный статус. Разместите на полку для выдачи';
			if (self.wrong_status_type === 2) return 'Неверный статус. Переместите в проблемные посылки';
			return '';
		},
	}));

export default Postal;

export interface IPostal extends SnapshotIn<typeof Postal> {}
