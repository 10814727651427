import DatePicker from '@components/datePicker';
import Input from '@components/input';
import InputNumber from '@components/inputNumber';
import InputTrackNumber from '@components/inputTrackNumber';
import { useStore } from '@hooks/useStore';
import PostOfficeSelection from '@pages/main/visits/components/visit/compoments/common/post-office-select';
import { AcceptanceService, IPostUnidentifiedPostal } from '@services/acceptance';
import { Form, Modal } from 'antd';
import { useForm } from 'antd/es/form/Form';
import dayjs, { Dayjs } from 'dayjs';
import { FC, useState } from 'react';

import { replaceNumber } from '../../../../../../../../../utils/replaceNumber';
import styles from './index.module.scss';

interface IProps {
	isShow: boolean;
	setIsShow: (val: boolean) => void;
}

const prefixText = 'BY0800';

const ModalUnidentifiedPostal: FC<IProps> = ({ isShow, setIsShow }) => {
	const { notice, print } = useStore();
	const [form] = useForm();
	const [valueDate, setValueDate] = useState<Dayjs | null>(null);
	const [isError, setIsError] = useState(false);

	const closeModal = (): void => {
		form.resetFields();
		setIsShow(false);
	};

	const onFinish = async (data: IPostUnidentifiedPostal): Promise<void> => {
		if (data.sender_phone_number) {
			data.sender_phone_number = Number(replaceNumber(data.sender_phone_number.toString()));
		}
		if (data.receiver_phone_number) {
			data.receiver_phone_number = Number(replaceNumber(data.receiver_phone_number.toString()));
		}
		if (data.arrival_at) {
			data.arrival_at = dayjs(data.arrival_at).format('YYYY-MM-DD');
		}
		if (data.number) {
			data.number = prefixText + data.number;
		}

		if (data.external_id || data.number) {
			setIsError(false);
			try {
				const res = await AcceptanceService.postUnidentifiedPostal(data);

				await print.setPrintPreview({
					type: res.data.unidentified_item_id ? 'unidentified-item-thermal' : 'thermal',
					id: res.data.unidentified_item_id || res.data.postal_item_id,
					messageError: res.data.unidentified_item_id
						? 'Совпадений не найдено. Отправлен запрос в службу поддержки. Наклейте маркировку неопознанного груза и разместите ПчО в зоне проблемных'
						: '',
					isRadioButtons: true,
					optionsRadioButtons: res.data.unidentified_item_id
						? [
								{ children: 'Термоярлык', value: 'unidentified-item-thermal' },
								{ children: 'Адресный ярлык', value: 'unidentified-item-label' },
						  ]
						: null,
				});

				closeModal();
			} catch (err: any) {
				notice.setNotice({ message: err?.error || err?.message || '' });
			}
		} else {
			setIsError(true);
		}
	};

	return (
		<Modal
			closable={false}
			maskClosable={true}
			title="Добавление неопознанного груза"
			okText="Продолжить"
			onCancel={closeModal}
			onOk={form.submit}
			width={400}
			open={isShow}
			centered
			destroyOnClose
		>
			<Form onFinish={onFinish} form={form} className={styles.form}>
				<Form.Item name="external_id">
					<Input name="external_id" placeholder="Внешний ID" />
				</Form.Item>
				<Form.Item name="sender_fio">
					<Input name="sender_fio" placeholder="ФИО отправителя" />
				</Form.Item>
				<Form.Item name="sender_phone_number">
					<Input name="sender_phone_number" isPhone placeholder="Телефон отправителя" />
				</Form.Item>
				<Form.Item name="receiver_fio">
					<Input name="receiver_fio" placeholder="ФИО получателя" />
				</Form.Item>
				<Form.Item name="receiver_phone_number">
					<Input name="receiver_phone_number" isPhone placeholder="Телефон получателя" />
				</Form.Item>
				<Form.Item name="arrival_at">
					<DatePicker
						name="arrival_at"
						value={valueDate}
						onChange={(e) => setValueDate(e)}
						className={styles.date__picker}
						placeholder="Дата приёмки"
						style={{ width: '100%' }}
					/>
				</Form.Item>
				<Form.Item name="store_id_start">
					<PostOfficeSelection placeholder="№ ОПС приемки" />
				</Form.Item>
				<Form.Item name="store_id_finish">
					<PostOfficeSelection placeholder="№ ОПС доставки" />
				</Form.Item>
				<div className={styles.weight}>
					<Form.Item name="weight">
						<InputNumber
							name="weight"
							onInput={(text) => form.setFieldValue('weight', Number(text))}
							className={styles.input}
							placeholder="Вес на ярлыке, кг"
						/>
					</Form.Item>
					<Form.Item name="weight_fact">
						<InputNumber
							name="weight_fact"
							onInput={(text) => form.setFieldValue('weight_fact', Number(text))}
							className={styles.input}
							placeholder="Вес фактический, кг"
						/>
					</Form.Item>
				</div>
				<Form.Item name="number">
					<InputTrackNumber
						onChange={(e) => form.setFieldValue('number', e)}
						name="number"
						prefixText={prefixText}
						label="Введите известные символы трек-номера"
						length={8}
					/>
				</Form.Item>
			</Form>
			{isError && <div className={styles.error}>Заполните внешний ID или трек-номер</div>}
		</Modal>
	);
};

export default ModalUnidentifiedPostal;
