import Button from '@components/buttons/button';
import Modal from '@components/modal';
import { useStore } from '@hooks/useStore';
import Icon from '@images/index';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import styles from './index.module.scss';

const ModalInfo: FC = () => {
	const {
		acceptance: {
			postal: {
				postalCargoSpaces: { contentModalInfo, setCloseInfoModal },
			},
		},
	} = useStore();

	return (
		<Modal
			classNameModal={styles.confirm}
			className={styles.confirm__body}
			title={null}
			footer={false}
			open={!!contentModalInfo}
			onCancel={setCloseInfoModal}
			hasLines={false}
			centered
			width={416}
			closable={false}
			destroyOnClose
		>
			<Icon fontSize={50} imageName="Info" className={styles.icon} />
			<div className={styles.text}>{contentModalInfo || ''}</div>
			<Button className={styles.button} onClick={setCloseInfoModal}>
				Закрыть
			</Button>
		</Modal>
	);
};

export default observer(ModalInfo);
