import Input from '@components/input';
import Modal from '@components/modal';
import { useStore } from '@hooks/useStore';
import { leaveEvent, listenChannel } from '@services/socket';
import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';

import styles from './index.module.scss';

const MovingModal: FC = () => {
	const {
		operator,
		shipment: {
			emall: {
				consolidation: { modalMoving },
			},
		},
	} = useStore();

	const isShow = modalMoving.show;

	const leave = (): void => {
		window.Echo && operator?.id && leaveEvent('.routing.number', operator.id);
	};

	useEffect(() => {
		if (isShow) {
			window.Echo &&
				operator?.id &&
				listenChannel(({ number }) => modalMoving.setInput(number), '.routing.number', operator?.id);
		}

		return leave;
	}, [isShow]);

	return (
		<Modal
			classNameModal={styles.modal}
			className={styles.body}
			title={null}
			okText="Заменить"
			cancelText="Отмена"
			open={isShow}
			onCancel={modalMoving.closeModal}
			onOk={modalMoving.submit}
			hasLines={false}
			centered
			width={436}
			closable={false}
			destroyOnClose
			okButtonProps={{ disabled: !modalMoving.inputFrom || !modalMoving.inputTo }}
		>
			<h3 className={styles.title}>Замена пломбы</h3>
			<p className={styles.description}>Отсканируйте пломбы грузомест</p>
			<Input
				className={styles.input}
				value={modalMoving.inputFrom}
				onChange={(evt) => modalMoving.setInputFrom(evt.target.value)}
				placeholder="Старая пломба"
				isTransliterate
			/>
			<Input
				className={styles.input}
				value={modalMoving.inputTo}
				onChange={(evt) => modalMoving.setInputTo(evt.target.value)}
				placeholder="Новая пломба"
				isTransliterate
			/>
		</Modal>
	);
};

export default observer(MovingModal);
