/* eslint-disable @typescript-eslint/no-empty-interface */
import { RefusalReasonCategory } from '@constants/index';
import OrderService, { CancelReasonType, CorrectionReasonType } from '@services/order';
import { PostalService, ReasonPostType } from '@services/postal';
import { destroy, flow, getParent, getRoot, Instance, isAlive, types } from 'mobx-state-tree';

import { RootStore } from '../index';
import { OrderItem } from './order-items';
import Visit from './visit';

const { model, number, string, array, maybeNull, literal, union, optional } = types;

const RefusalReasonType = union(literal(RefusalReasonCategory.Postal), literal(RefusalReasonCategory.Order));

const RefusalReasonModel = model('RefusalReasonModel', {
	id: number,
	reason_text: string,
});

const ItemToRefuseModel = model('ItemToRefuseModel', {
	id: number,
	orderItemId: maybeNull(number),
	reasonId: maybeNull(number),
});

const ItemToCorrectModel = model('ItemToCorrectModel', {
	orderItemId: number,
	reasonId: maybeNull(number),
});

const RefusalReasonsModel = model('RefusalReasonsModel', {
	id: number,
	type: RefusalReasonType,
	reasons: maybeNull(array(RefusalReasonModel)),
	reasonId: maybeNull(number),
	isItemsToRefuseAvailable: false,
	isModalOpened: false,
	itemsToRefuse: optional(array(ItemToRefuseModel), [{ orderItemId: null, reasonId: null, id: Date.now() }]),
	itemsToCorrect: optional(array(ItemToCorrectModel), []),
})
	.actions((self) => {
		return {
			loadReasons: flow(function* () {
				const root = getRoot<typeof RootStore>(self);

				root.setLoading(true);

				try {
					const res = yield PostalService.getPostalReasons(self.type);
					if (isAlive(self)) {
						self.reasons = res.data;
					}
				} catch (err: any) {
					root.notice.setNotice({
						message: err?.error || err?.message || '',
					});
				} finally {
					root.setLoading(false);
				}
			}),
			openModal() {
				self.isModalOpened = true;
			},
			closeModal() {
				self.isModalOpened = false;
				self.isItemsToRefuseAvailable = false;
				self.reasonId = null;
				this.refreshItemsToRefuse();
			},
			updateItemsToCorrect() {
				const visit = getParent<typeof Visit>(self);
				const orderItems = visit?.orderItems?.items || null;

				orderItems?.forEach((item, i) => {
					const isQuantityEqual = item.quantity === item.received_quantity;
					const itemToCorrect = self.itemsToCorrect.find(({ orderItemId }) => orderItemId === item.id);

					if (isQuantityEqual) {
						itemToCorrect && destroy(itemToCorrect);
					} else {
						!itemToCorrect &&
							self.itemsToCorrect.push({
								orderItemId: item.id,
								reasonId: visit.initialReceivedQuantity[i] === orderItems[i].quantity ? null : item.reason_id,
							});
					}
				});
			},
			setCorrectionReason(orderItemId: number, reasonId: number) {
				const item = self.itemsToCorrect.find((item) => item.orderItemId === orderItemId);
				if (item) {
					item.reasonId = reasonId;
				}
			},
			toggleItemsToRefuseAvailability() {
				self.isItemsToRefuseAvailable = !self.isItemsToRefuseAvailable;

				if (!self.isItemsToRefuseAvailable) {
					this.refreshItemsToRefuse();
				}
			},
			setReasonId(id: number) {
				self.reasonId = id;
			},
			refreshItemsToRefuse() {
				self.itemsToRefuse.forEach((item) => {
					destroy(item);
				});
				this.createItemToRefuse();
			},
			createItemToRefuse() {
				self.itemsToRefuse.push({
					orderItemId: null,
					reasonId: null,
					id: Date.now(),
				});
			},
			addItemToRefuse() {
				const visit = getParent<typeof Visit>(self);

				const lastItem = self.itemsToRefuse.at(-1);
				const isPrevItemValid = Boolean(lastItem?.orderItemId && lastItem?.reasonId);
				const isMaxCount = Number(visit?.orderItems?.items.length) <= self.itemsToRefuse.length;

				if (!isMaxCount && isPrevItemValid) {
					this.createItemToRefuse();
				}
			},
			removeItemToRefuse(id: number) {
				destroy(self.itemsToRefuse.find((item) => item.id === id));
				if (!self.itemsToRefuse.length) {
					this.createItemToRefuse();
				}
			},
			selectItemToRefuseId(id: number, orderItemId: number) {
				const item = self.itemsToRefuse.find((item) => item.id === id);

				if (item) {
					item.orderItemId = orderItemId;
				}
			},
			selectItemToRefuseReason(id: number, reasonId: number) {
				const item = self.itemsToRefuse.find((item) => item.id === id);

				if (item) {
					item.reasonId = reasonId;
				}
			},
			postPostalReason: flow(function* (postalNumber: string) {
				const root = getRoot<typeof RootStore>(self);

				const data: ReasonPostType = {
					number: postalNumber,
					ops_operators_id: root.operator?.id,
					reason_id: self.reasonId,
				};

				try {
					root.setLoading(true);
					yield PostalService.postalReasonPost(data);
				} catch (err: any) {
					root.notice.setNotice({ message: err?.error || err?.message || '' });
					return Promise.reject(err);
				} finally {
					root.setLoading(false);
				}
			}),
			postOrderReasons: flow(function* () {
				const root = getRoot<typeof RootStore>(self);

				const data: CancelReasonType = {
					cancel_reason_id: self.reasonId,
					order_items: self.itemsToRefuse?.map(({ orderItemId, reasonId }) => ({
						order_item_id: orderItemId,
						reason_id: reasonId,
					})),
				};

				!self.isItemsToRefuseAvailable && delete data.order_items;

				try {
					root.setLoading(true);
					yield OrderService.cancelOrder({ orderId: self.id }, data);
				} catch (err: any) {
					root.notice.setNotice({ message: err?.error || err?.message || '' });
					return Promise.reject(err);
				} finally {
					root.setLoading(false);
				}
			}),
			postCorrectionReasons: flow(function* () {
				const root = getRoot<typeof RootStore>(self);

				try {
					root.setLoading(true);

					const data: CorrectionReasonType = {
						order_items: self.itemsToCorrect?.map(({ orderItemId, reasonId }) => ({
							order_item_id: orderItemId,
							reason_id: reasonId,
						})),
					};
					yield OrderService.saveCorrection(self.id, data);
				} catch (err: any) {
					root.notice.setNotice({ message: err?.error || err?.message || '' });
					return Promise.reject(err);
				} finally {
					root.setLoading(false);
				}
			}),
		};
	})
	.views((self) => {
		return {
			get availableItemsToRefuse(): OrderItem[] | void {
				const visit = getParent<typeof Visit>(self);
				const orderItems = visit?.orderItems?.items || null;
				return orderItems?.filter(({ id }) => {
					return !self.itemsToRefuse.some(({ orderItemId }) => orderItemId === id);
				});
			},
			get hasRemoveOrAddItemCorrection(): boolean {
				const visit = getParent<typeof Visit>(self);
				const orderItems = visit?.orderItems?.items || null;

				const arrRemoveOrAddItem = orderItems?.filter(
					(el, i) =>
						el.received_quantity &&
						(el.received_quantity < visit.initialReceivedQuantity[i] ||
							el.received_quantity > visit.initialReceivedQuantity[i])
				);

				const arrCorrectionItemsFilter = self.itemsToCorrect.some(({ reasonId }) => reasonId === null);

				return !!arrRemoveOrAddItem?.length && arrCorrectionItemsFilter;
			},

			get isInitialState(): boolean {
				const visit = getParent<typeof Visit>(self);
				const orderItems = visit?.orderItems?.items || null;

				const getLengthInitialItems = orderItems?.filter(
					(el, i) => el.received_quantity === visit.initialReceivedQuantity[i]
				).length;

				return getLengthInitialItems === visit.initialReceivedQuantity.length;
			},
		};
	});

export default RefusalReasonsModel;
export interface RefusalReasonsModel extends Instance<typeof RefusalReasonsModel> {}
export type RefusalReasonModel = Instance<typeof RefusalReasonModel>;
export type RefusalReasonType = Instance<typeof RefusalReasonType>;
