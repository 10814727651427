import InputNumber from '@components/inputNumber';
import colors from '@config/colors';
import { useStore } from '@hooks/useStore';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { priceFormat } from '../../../../../../utils/priceFormat';
import styles from './index.module.scss';

const PostAndOrders: FC = () => {
	const {
		kassa: {
			reportFull: {
				card_post_services_paid,
				card_post_cod_paid,
				cash_post_services_paid,
				cash_post_cod_paid,
				cash_order_paid,
				card_order_paid,
				cash_post_money_back,
				certificate_post_paid,
				certificate_order_paid,
				setCashPostCodPaid,
				setCashPostServicesPaid,
				setCashOrderPaid,
				setCardPostCodPaid,
				setCardPostServicesPaid,
				setCardOrderPaid,
				setCertificateOrderPaid,
				setCertificatePostPaid,
				setCashPostMoneyBack,
				totalCertificatePostAndOrder,
				totalCashPostAndOrder,
				totalCardPostAndOrder,
				readonly,
			},
		},
	} = useStore();

	return (
		<div>
			<div style={{ display: 'flex', gap: 16, paddingBottom: 8, fontWeight: 500 }}>
				<div style={{ flex: 1 }}></div>
				<div style={{ flex: 1 }}>Почта</div>
				<div style={{ flex: 1 }}>Заказы</div>
				<div style={{ flex: 1 }}>Итого</div>
			</div>
			<div style={{ display: 'flex', flexDirection: 'column', gap: 16, color: colors.grey1 }}>
				<div style={{ display: 'flex', gap: 16, alignItems: 'flex-start' }}>
					<div style={{ flex: 1 }}>Наличными</div>
					<div style={{ display: 'flex', flexDirection: 'column', gap: 16, flex: 1 }}>
						<InputNumber
							className={styles.input}
							value={priceFormat((cash_post_cod_paid || 0) + (cash_post_services_paid || 0)).replace(',', '.')}
							disabled={true}
						/>
						<InputNumber
							className={styles.input__sub}
							placeholder="За НП"
							value={cash_post_cod_paid || 0}
							setValue={setCashPostCodPaid}
							disabled={readonly}
						/>
						<InputNumber
							className={styles.input__sub}
							placeholder="За услугу"
							value={cash_post_services_paid || 0}
							setValue={setCashPostServicesPaid}
							disabled={readonly}
						/>
					</div>
					<div style={{ flex: 1 }}>
						<InputNumber
							className={styles.input}
							value={cash_order_paid || 0}
							setValue={setCashOrderPaid}
							disabled={readonly}
						/>
					</div>
					<div style={{ flex: 1 }}>
						<InputNumber className={styles.input} value={totalCashPostAndOrder || 0} disabled />
					</div>
				</div>
				<div style={{ display: 'flex', gap: 16, alignItems: 'flex-start' }}>
					<div style={{ flex: 1 }}>Картой</div>
					<div style={{ display: 'flex', flexDirection: 'column', gap: 16, flex: 1 }}>
						<InputNumber
							className={styles.input}
							value={priceFormat((card_post_cod_paid || 0) + (card_post_services_paid || 0)).replace(',', '.')}
							disabled={true}
						/>
						<InputNumber
							className={styles.input__sub}
							placeholder="За НП"
							value={card_post_cod_paid || 0}
							setValue={setCardPostCodPaid}
							disabled={readonly}
						/>
						<InputNumber
							className={styles.input__sub}
							placeholder="За услугу"
							value={card_post_services_paid || 0}
							setValue={setCardPostServicesPaid}
							disabled={readonly}
						/>
					</div>
					<div style={{ flex: 1 }}>
						<InputNumber
							className={styles.input}
							value={card_order_paid || 0}
							setValue={setCardOrderPaid}
							disabled={readonly}
						/>
					</div>
					<div style={{ flex: 1 }}>
						<InputNumber className={styles.input} value={totalCardPostAndOrder || 0} disabled />
					</div>
				</div>
				<div style={{ display: 'flex', gap: 16, alignItems: 'center' }}>
					<div style={{ flex: 1 }}>Сертификатом</div>
					<div style={{ flex: 1 }}>
						<InputNumber
							className={styles.input}
							value={certificate_post_paid || 0}
							setValue={setCertificatePostPaid}
							disabled={readonly}
						/>
					</div>
					<div style={{ flex: 1 }}>
						<InputNumber
							className={styles.input}
							value={certificate_order_paid || 0}
							setValue={setCertificateOrderPaid}
							disabled={readonly}
						/>
					</div>
					<div style={{ flex: 1 }}>
						<InputNumber className={styles.input} value={totalCertificatePostAndOrder || 0} disabled />
					</div>
				</div>
				<div style={{ display: 'flex', gap: 16, alignItems: 'center' }}>
					<div style={{ flex: 1 }}>Выплата НП</div>
					<div style={{ flex: 1 }}>
						<InputNumber
							className={styles.input}
							value={cash_post_money_back || 0}
							setValue={setCashPostMoneyBack}
							disabled={readonly}
						/>
					</div>
					<div style={{ flex: 1 }}></div>
					<div style={{ flex: 1 }}></div>
				</div>
				{/* <div style={{ display: 'flex', gap: 16, alignItems: 'center' }}>
					<div style={{ flex: 1 }}>Выплата ДП</div>
					<div style={{ flex: 1 }}>
						<Input style={inputStyle} disabled={kassa.reportFull?.readonly} />
					</div>
					<div style={{ flex: 1 }}></div>
					<div style={{ flex: 1 }}></div>
				</div> */}
			</div>
		</div>
	);
};

export default observer(PostAndOrders);
