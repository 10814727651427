import { api } from '@http/index';
import { Order } from '@models/index';
import { IOrderCollectItem, IOrdersCollect } from '@store/visits/order-collect';
import { OrderItems } from '@store/visits/order-items';
import { AxiosResponse } from 'axios';

interface OrderItemReasonType {
	order_item_id: number | null;
	reason_id: number | null;
}

export interface CancelReasonType {
	cancel_reason_id?: number | null;
	order_items?: OrderItemReasonType[];
}

export interface CorrectionReasonType {
	order_items?: OrderItemReasonType[];
	reason_id?: number | null;
}

export interface IOrderItemsCollectResponse {
	order_market_ids: number[];
	items: {
		name: string;
		quantity: number;
	}[];
}

export interface IPostOrderScanManual {
	status: number;
	status_message: string;
}

const getOrders = (orderId: number): Promise<AxiosResponse<Order>> => {
	return api.get(`order/${orderId}/all`);
};

const getOrder = (orderId: number): Promise<AxiosResponse<Order>> => {
	return api.get(`order/${orderId}`);
};

const getOrderItems = ({ orderId }: { orderId: number }): Promise<AxiosResponse<OrderItems>> => {
	return api.get(`order/${orderId}/items`);
};

const cancelOrder = ({ orderId }: { orderId: number }, data?: CancelReasonType): Promise<AxiosResponse<void>> => {
	return api.post(`order/${orderId}/cancel`, data);
};

const cancelCorrection = ({ orderId }: { orderId: number }): Promise<AxiosResponse<OrderItems>> => {
	return api.post(`order/${orderId}/correction/cancel`);
};

const saveCorrection = (orderId: number, data?: CorrectionReasonType): Promise<AxiosResponse<OrderItems>> => {
	return api.post(`order/${orderId}/correction`, data);
};

const changeOrderItem = ({
	orderId,
	itemId,
	count,
}: {
	orderId: number;
	itemId?: number;
	count?: number;
}): Promise<AxiosResponse<OrderItems>> => {
	return api.post(`/order/${orderId}/item/${itemId}/correction`, { received_quantity: count });
};

const confirmOrder = (data: {
	orders: number[];
	cash_payment: number;
	cashless_payment: number;
}): Promise<AxiosResponse<OrderItems>> => {
	return api.post(`order/received`, data);
};

const extendOrder = (id: number): Promise<AxiosResponse<{ message: string }>> => {
	return api.put(`/order/${id}/storage/extend`);
};

const getOrderForCollect = (arrayNumber: number[]): Promise<AxiosResponse<IOrdersCollect>> => {
	const query: string =
		arrayNumber.length > 1 ? arrayNumber.map((el) => `order_ids[]=${el}`).join('&') : `order_ids[]=${arrayNumber[0]}`;
	return api.get(`/order/items-for-collect?${query}`);
};

const postOrderItemsCollect = (data: { order_ids: number[] }): Promise<AxiosResponse<IOrderItemsCollectResponse>> => {
	return api.post('/order/items/collect', data);
};

const postOrderItemsCollectWithCorrection = (data: { order_ids: number[] }): Promise<AxiosResponse<void>> => {
	return api.post('/order/items/collect-with-correction', data);
};

const postOrderScan = (data: { barcode: number; orders: number[] }): Promise<AxiosResponse<IPostOrderScanManual>> =>
	api.post('order/item/scan', data);

const postOrderScanManual = (
	data: Pick<IOrderCollectItem, 'product_id' | 'price' | 'store_place_item_id' | 'quantity'> & { orders: number[] }
): Promise<AxiosResponse<IPostOrderScanManual>> => api.post('order/item/manual-scan', data);

const OrderService = {
	getOrders,
	getOrder,
	getOrderItems,
	cancelOrder,
	changeOrderItem,
	cancelCorrection,
	saveCorrection,
	confirmOrder,
	extendOrder,
	getOrderForCollect,
	postOrderItemsCollect,
	postOrderItemsCollectWithCorrection,
	postOrderScan,
	postOrderScanManual,
};

export default OrderService;
