import Button from '@components/buttons/button';
import { useStore } from '@hooks/useStore';
import Icon from '@images/index';
import { Modal } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import styles from './index.module.scss';

const ModalConfirmUnidentified: FC = () => {
	const {
		acceptance: { postal },
	} = useStore();

	const close = (): void => {
		postal.closeModalConfirmSpacesUnidentified();
		postal.setIsShowModalUnidentifiedCargo(false);
	};

	return (
		<Modal
			open={!!postal.responseGetSpacesUnidentified}
			onCancel={postal.closeModalConfirmSpacesUnidentified}
			centered
			closeIcon={false}
			className={styles.modal}
			title={false}
			footer={false}
		>
			<Icon
				className={cn(styles.icon, {
					[styles.icon__red]:
						postal.responseGetSpacesUnidentified?.status.toString() &&
						[0, 2].includes(postal.responseGetSpacesUnidentified?.status),
					[styles.icon__green]:
						postal.responseGetSpacesUnidentified?.status.toString() &&
						[1].includes(postal.responseGetSpacesUnidentified?.status),
				})}
				imageName={postal.getIconName(postal.responseGetSpacesUnidentified?.status || 0)}
				fontSize={72}
			/>
			<div className={styles.heading}>{postal.responseGetSpacesUnidentified?.title || ''}</div>
			<div
				className={cn(styles.title, {
					[styles.title__red]:
						postal.responseGetSpacesUnidentified?.status &&
						[0, 2].includes(postal.responseGetSpacesUnidentified?.status),
				})}
			>
				{postal.responseGetSpacesUnidentified?.description || ''}
			</div>
			{postal.responseGetSpacesUnidentified?.status !== 0 && (
				<div className={styles.buttons}>
					<Button onClick={close}>Закрыть</Button>
					{postal.responseGetSpacesUnidentified?.status === 1 && (
						<Button
							type="primary"
							onClick={() => {
								postal.responseGetSpacesUnidentified?.id &&
									postal.postalCargoSpaces.setStartScanned(postal.responseGetSpacesUnidentified?.id);
								close();
							}}
						>
							Перейти к приемке
						</Button>
					)}
				</div>
			)}
		</Modal>
	);
};

export default observer(ModalConfirmUnidentified);
