import Button from '@components/buttons/button';
import IconButton from '@components/buttons/icon_btn';
import Modal from '@components/modal';
import { useStore } from '@hooks/useStore';
import { Sheet } from '@store/acceptance/route-sheet';
import Table, { ColumnsType } from 'antd/es/table';
import { observer } from 'mobx-react-lite';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { FC, useState } from 'react';

import styles from './index.module.scss';

const SheetByShipmentModal: FC = () => {
	const [id, setId] = useState<number | null>(null);
	const {
		print,
		acceptance: {
			routeSheet: { modalSheetByShipment },
		},
	} = useStore();

	if (!modalSheetByShipment) return null;

	const { show, setShow, spaces, confirm } = modalSheetByShipment;

	const columns: ColumnsType<Sheet> = [
		{
			dataIndex: 'info',
			render: () => (
				<div className={styles.info}>
					<span>Маршрутный лист</span>
				</div>
			),
			width: 162,
		},
		{
			dataIndex: 'type',
			render: (_, { has_emall, has_postal }) => (
				<span>
					{(has_postal && 'Почта') || ''}
					{(has_postal && has_emall && '/') || ''}
					{(has_emall && 'Emall') || ''}
				</span>
			),
			width: 120,
		},
		{
			dataIndex: 'id',
			render: (_, { id }) => <span style={{ width: 136 }}>{id}</span>,
			width: 120,
		},
		{
			dataIndex: 'date',
			render: (_, { date }) => <span>{date || ''}</span>,
			width: 120,
		},
		{
			dataIndex: 'count',
			render: (_, { routing_spaces_count }) => <span>Состав: {routing_spaces_count} шт.</span>,
		},
		{
			dataIndex: 'actions',
			render: (_, { id }) => (
				<div className={styles.actions}>
					<IconButton
						imageName="Print"
						onClick={(evt) => {
							evt.stopPropagation();
							print.printTask({ type: 'routing-lists', id });
						}}
					/>
				</div>
			),
			width: 45,
		},
	];

	return (
		<Modal
			title="Выберите маршрутные листы на отгрузку экспедитору"
			footer={
				<div className={styles.footer}>
					<Button onClick={() => confirm({ isSkip: true })}>Пропустить</Button>
					<Button type="primary" onClick={() => id && confirm({ id })} disabled={!id}>
						Передать
					</Button>
				</div>
			}
			open={show}
			width={880}
			onCancel={setShow.bind(null, false)}
			className={styles.body}
			classNameModal={styles.modal}
			centered
			destroyOnClose
		>
			<OverlayScrollbarsComponent className={styles.scroll} options={{ scrollbars: { autoHide: 'move' } }}>
				<Table
					showHeader={false}
					pagination={false}
					columns={columns}
					dataSource={[...spaces]}
					onRow={() => ({ className: styles.row })}
					className={styles.table}
					locale={{ emptyText: 'Нет данных' }}
					rowKey={(item) => item.id}
					rowSelection={{
						type: 'radio',
						onChange: (selectedRowKeys: React.Key[], selectedRows: Sheet[]) => setId(selectedRows[0].id),
						getCheckboxProps: (record: Sheet) => ({ value: record.id }),
					}}
				/>
			</OverlayScrollbarsComponent>
		</Modal>
	);
};

export default observer(SheetByShipmentModal);
