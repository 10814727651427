import Button from '@components/buttons/button';
import Modal from '@components/modal';
import { useStore } from '@hooks/useStore';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import styles from './index.module.scss';

const WrongModal: FC = () => {
	const {
		shipment: {
			post: { consolidation },
		},
	} = useStore();

	const close = (): void => {
		consolidation.setModalWrong('');
	};

	return (
		<Modal
			classNameModal={styles.modal}
			className={styles.body}
			open={!!consolidation.modalWrong}
			onCancel={close}
			hasLines={false}
			centered
			width={416}
			closable={false}
			destroyOnClose
			footer={
				<Button className={styles.button} onClick={close}>
					Закрыть
				</Button>
			}
		>
			<h3 className={styles.title}>Достать посылку из мешка</h3>
			<p className={styles.description}>Отсканируйте посылку повторно</p>
		</Modal>
	);
};

export default observer(WrongModal);
