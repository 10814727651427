/* eslint-disable @typescript-eslint/no-empty-interface */
import { cast, SnapshotIn, types } from 'mobx-state-tree';

const { model, number, string, boolean, maybeNull, union, literal } = types;

const SelectedItem = model('selected-item', {
	id: number,
	type: union(literal('postal'), literal('order')),
});

const DataOpsChange = model('data-change', {
	id: number,
	number: string,
	isJuristic: boolean,
}).actions((self) => ({
	setNumber(value: string) {
		self.number = value;
	},
}));

const ExtendedSearch = model('extended-search', {
	selectedItem: maybeNull(SelectedItem),
	dataOpsChange: maybeNull(DataOpsChange),
}).actions((self) => ({
	setSelectedItem(item: ISelectedItem | null) {
		self.selectedItem = item;
	},
	setDataOpsChange(data: { id: number; number: string; isJuristic: boolean } | null) {
		self.dataOpsChange = cast(data);
	},
}));

export default ExtendedSearch;

export interface ISelectedItem extends SnapshotIn<typeof SelectedItem> {}
