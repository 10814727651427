import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import Button from '@components/buttons/button';
import Modal from '@components/modal';
import { useStore } from '@hooks/useStore';
import Icon from '@images/index';
import { Radio } from 'antd';
import cn from 'classnames';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { QRCodeSVG } from 'qrcode.react';
import { FC, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { getGlobalBlob } from 'utils/globalObject';

import {
	DocumentKeys,
	documentTypes,
} from '../../pages/main/visits/components/visit/compoments/postal-table/components/documentTypes';
import styles from './index.module.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.min.js`;
export interface PdfModalProps {
	data?: { type: DocumentKeys; id: number; number: string };
	footer?: React.ReactNode;
}

const PdfModal: FC<PdfModalProps> = ({ footer }) => {
	const store = useStore();
	const data = store.print.printPreview;
	const isOpen = !!store.print.printPreview;
	const [numPages, setNumPages] = useState<number>(0);
	const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => setNumPages(numPages);

	const handleRadioButton = async (type: DocumentKeys): Promise<void> => {
		if (data) {
			await store.print.setPrintPreview({ ...toJS(store.print.printPreview), type, id: data.id });
		}
	};

	const dataRadioButtons = store.print.printPreview?.optionsRadioButtons
		? store.print.printPreview?.optionsRadioButtons
		: [
				{ children: 'Термоярлык', value: 'thermal' },
				{ children: 'Адресный ярлык', value: 'label' },
		  ];

	return (
		<Modal
			classNameModal={styles.modal}
			className={cn(styles.body, { [styles.body__inner]: !data?.manualQr })}
			title={
				<>
					<span>
						{data?.type ? `${documentTypes[data.type as keyof typeof documentTypes]} ${data.number || ''}` : ''}
					</span>
					{data?.description && <span className={styles.description}>{data.description}</span>}
					{store.print.printPreview?.messageError && (
						<span className={styles.title__warning}>{store.print.printPreview?.messageError}</span>
					)}
				</>
			}
			open={isOpen}
			onCancel={store.print.cancelPrintPreview}
			centered
			width={data?.manualQr ? 480 : 1022}
			footer={
				<footer className={styles.footer}>
					{footer || (
						<>
							<Button onClick={store.print.cancelPrintPreview}>Закрыть</Button>
							<Button
								icon={<Icon imageName="Print" />}
								type="primary"
								onClick={() => {
									store.print.printPreview &&
										store.print.printTask({
											...toJS(store.print.printPreview),
										});
								}}
								disabled={!store.print.pdfPreviewDataId}
							>
								Печать
							</Button>
						</>
					)}
				</footer>
			}
			destroyOnClose
		>
			{data?.manualQr ? (
				<QRCodeSVG value={data.manualQr} size={224} />
			) : (
				<div className={styles.wrapper}>
					{store.print.printPreview?.isRadioButtons && (
						<Radio.Group
							className={styles.radio__buttons}
							defaultValue={dataRadioButtons[0].value}
							onChange={(e) => handleRadioButton(e.target.value)}
						>
							{dataRadioButtons.map((el: any) => (
								<Radio key={el.value} value={el.value}>
									{el.children}
								</Radio>
							))}
						</Radio.Group>
					)}
					<div className={styles.wrap}>
						{store.print.pdfPreviewDataId ? (
							<Document
								className={cn(styles.label, { [styles.label__thermal]: data?.type === 'thermal' })}
								file={getGlobalBlob(store.print.printPreview?.taskId || '')}
								loading="Загрузка документа"
								onLoadSuccess={onDocumentLoadSuccess}
							>
								{Array.from({ length: numPages }, (_, index) => (
									<div className={styles.wrapperPage} key={`page_${index + 1}`}>
										<Page pageNumber={index + 1} width={650} height={590} scale={5} loading="Загрузка страницы" />
										{numPages > 1 && (
											<p style={{ textAlign: 'center' }}>
												{index + 1} из {numPages}
											</p>
										)}
									</div>
								))}
							</Document>
						) : (
							!store.loading && 'Ошибка при загрузке документа'
						)}
					</div>
				</div>
			)}
		</Modal>
	);
};

export default observer(PdfModal);
