import Input from '@components/input';
import Modal from '@components/modal';
import Select from '@components/select';
import colors from '@config/colors';
import { RefusalReasonCategory } from '@constants/index';
import { useCurrentVisit } from '@hooks/useCurrentVisit';
import Icon from '@images/index';
import { PostalService } from '@services/postal';
import { Button, Checkbox, Form } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';

import ActionConfirmModal from '../action-confirm-modal';
import OrderItems from './components/order-items';
import styles from './index.module.scss';

const RefusalReceivingModal: FC = () => {
	const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false);
	const visit = useCurrentVisit();
	const refusalReasons = visit?.refusalReasons;
	const id = refusalReasons?.id;
	const type = refusalReasons?.type;

	const order = id && type === RefusalReasonCategory.Order ? visit?.getOrder(id) : null;
	const postal = id && type === RefusalReasonCategory.Postal ? visit?.getPostal(id) : null;
	const receiver = postal?.receiver || null;

	const number = type === RefusalReasonCategory.Postal ? postal?.number : order?.market_id;
	const phone = type === RefusalReasonCategory.Postal ? receiver?.phone_number : order?.receiver_phone;
	const fio = type === RefusalReasonCategory.Postal ? receiver?.fio : order?.receiver_fio;
	const reasons = refusalReasons?.reasons;

	const [dataRoutePostal, setDataRoutePostal] = useState<number[]>([]);

	const getDataRoutePostal = async (id: number): Promise<void> => {
		try {
			const res = await PostalService.getPostalInfo(id);
			//@ts-ignore
			setDataRoutePostal([res.data.stores.start?.id, res.data.stores.finish?.id]);
		} catch (err: any) {
			console.log(err);
		}
	};

	useEffect(() => {
		postal && id && getDataRoutePostal(id);
	}, [postal]);

	const handlePostalConfirmClick = async (): Promise<void> => {
		if (refusalReasons?.reasonId && postal && id) {
			try {
				await refusalReasons.postPostalReason(postal.number);
				refusalReasons.closeModal();
				setIsConfirmModalOpened(false);
				visit?.icidents?.close();
				visit?.deletePostal(id);
			} catch {
				return;
			}
		}
	};

	const handleOrderConfirmClick = async (): Promise<void> => {
		if (id && refusalReasons) {
			try {
				await refusalReasons.postOrderReasons();
				visit?.deleteOrder(id);
				refusalReasons.closeModal();
				setIsConfirmModalOpened(false);
				visit?.setModalCorrectionId(null);
			} catch {
				return;
			}
		}
	};

	return (
		<Modal
			centered
			title="Отказ от вручения"
			width={400}
			open={refusalReasons?.isModalOpened}
			destroyOnClose
			className={styles.modal}
			maskClosable={false}
			footer={null}
			onCancel={refusalReasons?.closeModal}
		>
			<Form onFinish={() => setIsConfirmModalOpened(true)}>
				<div className={styles.body}>
					<Input
						placeholder={`Номер ${type === RefusalReasonCategory.Postal ? 'ПО' : 'заказа'}`}
						value={number}
						disabled
					/>
					<Input placeholder="Получатель" value={fio} disabled />
					<Input placeholder="Телефон" value={phone || ''} disabled />

					<Form.Item
						style={{ marginBottom: 0 }}
						name="reason"
						rules={[{ required: true, message: <span className={styles.error}>Выберите причину</span> }]}
					>
						<Select
							required
							placeholder="Причина"
							options={reasons?.map(({ id, reason_text }) => ({
								label: `${id} - ${reason_text}`,
								value: id,
							}))}
							isActive={!!refusalReasons?.reasonId}
							onChange={(id) => {
								refusalReasons?.setReasonId(Number(id));
							}}
						/>
					</Form.Item>

					{refusalReasons?.type === RefusalReasonCategory.Order && (
						<Checkbox
							checked={refusalReasons?.isItemsToRefuseAvailable}
							onChange={refusalReasons?.toggleItemsToRefuseAvailability}
						>
							Причина потоварно
						</Checkbox>
					)}

					{refusalReasons?.isItemsToRefuseAvailable && <OrderItems />}

					<div className={styles.warning}>
						<Icon imageName="ExclamationCircle" fontSize={14} />
						<p>
							<strong>Внимание!</strong> Подтверждение действия безвозвратное
						</p>
					</div>

					{dataRoutePostal?.length && dataRoutePostal[0] === dataRoutePostal[1] ? (
						<div
							style={{
								padding: '8px 16px',
								borderWidth: 1,
								borderColor: colors.dustRed2,
								background: colors.dustRed1,
								color: colors.red,
								borderStyle: 'solid',
								marginBottom: 16,
							}}
						>
							Оставьте посылку на ОПС для выдачи возврата
						</div>
					) : null}
				</div>
				<div className={styles.footer}>
					<Form.Item noStyle>
						<Button htmlType="button" onClick={refusalReasons?.closeModal}>
							Отмена
						</Button>
						<Button type="primary" htmlType="submit" disabled={!refusalReasons?.reasonId}>
							Отправить
						</Button>
					</Form.Item>
				</div>
			</Form>
			<ActionConfirmModal
				title={`Вы уверены, что хотите ${
					type === RefusalReasonCategory.Postal ? 'отправить на возврат посылку' : 'отменить заказ'
				}?`}
				okText={`${type === RefusalReasonCategory.Postal ? 'Возврат ПчО' : 'Отменить заказ'}`}
				open={isConfirmModalOpened}
				onCancel={() => {
					setIsConfirmModalOpened(false);
				}}
				onOk={type === RefusalReasonCategory.Postal ? handlePostalConfirmClick : handleOrderConfirmClick}
			/>
		</Modal>
	);
};

export default observer(RefusalReceivingModal);
