import Button from '@components/buttons/button';
import Input from '@components/input';
import Modal from '@components/modal';
import { useStore } from '@hooks/useStore';
import PrintMenu from '@pages/main/acceptance/components/print-menu';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';

import styles from './index.module.scss';

const Header: FC = () => {
	const {
		acceptance: {
			postal,
			postal: {
				postalCargoSpaces: { modalItems, modalItemsSingle },
			},
		},
	} = useStore();

	const [barcodeModal, setBarcodeModal] = useState(false);
	const [barcode, setBarcode] = useState<string>('');
	const [barcodeError, setBarcodeError] = useState('');

	const closeBarcodeModal = (): void => {
		setBarcodeModal(false);
		setBarcode('');
		setBarcodeError('');
	};

	return (
		<>
			<header className={styles.header}>
				{postal.postalCargoSpaces.postalCargoSpacesItems &&
					[84].includes(
						postal.postalCargoSpaces.postalCargoSpacesItems?.routing_spaces?.find(
							(el: any) => el.number === modalItems?.number
						)?.status as number
					) && (
						<div>
							<Button className={styles.button__code} type="text" onClick={() => setBarcodeModal(true)}>
								Ввести трек-номер вручную
							</Button>
							<PrintMenu />
						</div>
					)}
				<span className={styles.counter}>
					<span className={styles.counter__current}>
						{modalItems?.routing_items?.filter((el) => el.store_place_item_full_name)?.length || 0}
						{modalItemsSingle && modalItemsSingle?.filter((el) => el.status === 2).length !== 0 && (
							<span>+{modalItemsSingle?.filter((el) => el.status === 2).length}</span>
						)}
					</span>
					<span>/{modalItems?.routing_items?.length || 0}</span>
				</span>
			</header>
			<Modal
				classNameModal={styles.modal}
				className={styles.modal__body}
				title={null}
				okText="Добавить"
				okButtonProps={{ disabled: !!barcodeError || !barcode }}
				cancelButtonProps={{ style: { display: 'none' } }}
				open={barcodeModal}
				onCancel={closeBarcodeModal}
				onOk={() => {
					if (!barcode) return;
					postal
						?.scanBarcode(String(barcode))
						.then(closeBarcodeModal)
						.catch((err: any) => setBarcodeError(err));
				}}
				hasLines={false}
				centered
				width={416}
				closable={false}
				destroyOnClose
			>
				<h3 className={styles.modal__title}>Введите трек-номер</h3>
				<Input className={styles.input} value={barcode} onChange={(e) => setBarcode(e.target.value)} />
				{barcodeError ? <p className={styles.error}>{barcodeError}</p> : null}
			</Modal>
		</>
	);
};

export default observer(Header);
