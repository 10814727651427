import Button from '@components/buttons/button';
import { useStore } from '@hooks/useStore';
import { Modal as AntModal, ModalProps as AntModalProps } from 'antd';
import cn from 'classnames';
import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import { addZero } from 'utils/addZero';

import styles from './index.module.scss';

type ModalProps = Omit<AntModalProps, 'children'>;

let id: ReturnType<typeof setInterval>;

const ModalBreak: FC<ModalProps> = ({ className, open, ...props }) => {
	const [minutes, setMinutes] = useState(0);
	const [seconds, setSeconds] = useState(0);
	const store = useStore();

	useEffect(() => {
		clearInterval(id);

		if (open && store.shift?.break) {
			const startTime = dayjs(store.shift.break.opened_at);
			const diff = dayjs().diff(startTime, 'second');
			setMinutes(Math.floor(diff / 60));
			setSeconds(diff % 60);

			id = setInterval(() => {
				setSeconds((sec) => {
					if (sec === 59) {
						setMinutes((min) => min + 1);
						setSeconds(0);
						return 0;
					}

					return sec + 1;
				});
			}, 1000);
		}

		return () => clearInterval(id);
	}, [open]);

	return (
		<AntModal
			{...props}
			title={
				<h2 className={styles.title}>
					Перерыв {addZero(minutes)}:{addZero(seconds)}
				</h2>
			}
			open={open}
			closable={false}
			className={cn(className, styles.modal)}
			width={416}
			footer={
				<Button className={styles.button} type="primary" onClick={store.closeBreak}>
					Завершить перерыв
				</Button>
			}
			centered
		>
			{/* <p className={cn(className, styles.body)}>
				Для завершения перерыва Вы будете перенаправлены на страницу авторизации
			</p> */}
		</AntModal>
	);
};

export default ModalBreak;
