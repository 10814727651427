import Input from '@components/input';
import Modal from '@components/modal';
import { useStore } from '@hooks/useStore';
import { leaveEvent, listenChannel } from '@services/socket';
import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';

import styles from './index.module.scss';

const ConfirmModal: FC = () => {
	const {
		operator,
		shipment: {
			emall: {
				consolidation: { modalGoodsTable: modal },
			},
		},
	} = useStore();

	const leave = (): void => {
		window.Echo && operator?.id && leaveEvent('.routing.number', operator.id);
	};

	useEffect(() => {
		if (window.Echo && operator?.id && modal?.showConfirm) {
			listenChannel(({ number }) => modal?.setSign(number), '.routing.number', operator.id);
		}

		return leave;
	}, [modal?.showConfirm]);

	return (
		<Modal
			classNameModal={styles.confirm}
			className={styles.confirm__body}
			title={null}
			okText="Продолжить"
			cancelText="Отмена"
			open={modal?.showConfirm}
			onCancel={modal?.closeConfirm}
			onOk={modal?.submit}
			okButtonProps={{ disabled: !modal?.sign }}
			hasLines={false}
			centered
			width={416}
			closable={false}
			destroyOnClose
		>
			<h3 className={styles.confirm__title}>Отсканируйте пломбу, которой закроете ящик</h3>
			<Input value={modal?.sign} onChange={(evt) => modal?.setSign(evt.target.value)} isTransliterate />
		</Modal>
	);
};

export default observer(ConfirmModal);
