import Input from '@components/input';
import { useStore } from '@hooks/useStore';
import { AcceptanceService, IPostPostalTroubleItem } from '@services/acceptance';
import { Form, Modal } from 'antd';
import { useForm } from 'antd/es/form/Form';
import TextArea from 'antd/lib/input/TextArea';
import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';

const ModalProblem: FC = () => {
	const [form] = useForm();

	const {
		notice,
		acceptance: {
			postal: { postalTrouble },
		},
	} = useStore();

	const initialValues: IPostPostalTroubleItem = {
		id: postalTrouble?.dataModalProblem?.id || 0,
		status: postalTrouble?.dataModalProblem?.status || 0,
		number: postalTrouble?.dataModalProblem?.number || '',
	};

	useEffect(() => {
		if (postalTrouble?.dataModalProblem) {
			form.setFieldsValue(initialValues);
		}
	}, [postalTrouble?.dataModalProblem]);

	const onFinish = async (data: IPostPostalTroubleItem): Promise<void> => {
		try {
			await AcceptanceService.postSendTroubleItem(data);
			postalTrouble?.closeModalItemProblem();
			form.resetFields();
			notice.setNotice({ type: 'success', message: `Сообщение о проблемной посылке ${data.number} отправлено` });
			await postalTrouble.loadPostalTroubleSpaces();
		} catch (err: any) {
			postalTrouble?.closeModalItemProblem();
			form.resetFields();
			notice.setNotice({ message: err?.error || err?.message || '' });
		}
	};

	return (
		<Modal
			open={!!postalTrouble?.dataModalProblem}
			width={400}
			title="Сообщить о проблеме"
			okText="Отправить"
			closable={false}
			maskClosable
			onCancel={postalTrouble?.closeModalItemProblem}
			centered
			destroyOnClose
			onOk={form.submit}
		>
			<Form onFinish={onFinish} form={form} initialValues={initialValues}>
				<Form.Item noStyle name="id" />
				<Form.Item name="number">
					<Input disabled placeholder="Номер посылки" />
				</Form.Item>
				<Form.Item name="status">
					<Input disabled placeholder="Текущий статус" />
				</Form.Item>
				<Form.Item name="comment">
					<TextArea
						rows={5}
						placeholder="Укажите вес посылки если она в 0-м статусе или опишите обстоятельства обнаружения ПчО"
					/>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default observer(ModalProblem);
