/* eslint-disable @typescript-eslint/no-empty-interface */
import { Instance, types } from 'mobx-state-tree';

const { model, number, union, literal, maybeNull, optional } = types;

const Driver = model('driver', {
	id: maybeNull(number),
	isMercenary: optional(union(literal(0), literal(1)), 0),
});

export default Driver;

export interface IDriver extends Instance<typeof Driver> {}
