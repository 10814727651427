import Input from '@components/input';
import Modal from '@components/modal';
import { useStore } from '@hooks/useStore';
import { AcceptanceService } from '@services/acceptance';
import { ShipmentService } from '@services/shipment';
import { leaveEvent, listenChannel } from '@services/socket';
import { InputRef } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useRef, useState } from 'react';

import styles from './index.module.scss';

const MovingModal: FC = () => {
	const inputFromRef = useRef<InputRef | null>(null);
	const [inputFrom, setInputFrom] = useState('');
	const [inputTo, setInputTo] = useState('');

	const {
		operator,
		notice,
		shipment: {
			post: { consolidation },
		},
		setLoading,
	} = useStore();

	const closeModal = (): void => {
		consolidation.showModalMoving(false);
		setInputFrom('');
		setInputTo('');
		leave();
	};

	const leave = (): void => {
		window.Echo && operator?.id && leaveEvent('.routing.number', operator.id);
	};

	useEffect(() => {
		if (consolidation.modalMoving) {
			window.Echo &&
				operator?.id &&
				listenChannel(
					({ number }) => {
						let hasFrom = false;

						setInputFrom((value) => {
							if (value) {
								hasFrom = true;
								return value;
							}
							return number;
						});

						setInputTo((value) => {
							if (!hasFrom || value) return value;
							return number;
						});
					},
					'.routing.number',
					operator?.id
				);
		}

		return leave;
	}, [consolidation.modalMoving]);

	return (
		<Modal
			classNameModal={styles.modal}
			className={styles.body}
			title={null}
			okText="Заменить"
			cancelText="Отмена"
			open={consolidation.modalMoving}
			onCancel={closeModal}
			onOk={async () => {
				setLoading(true);

				try {
					const res = await AcceptanceService.getSpace(inputFrom);
					await ShipmentService.putSpaces(res.data.id, { number: inputTo });
					consolidation.loadSpaces();
					closeModal();
				} catch (err: any) {
					notice.setNotice({ message: err?.error || err?.message || '' });
				} finally {
					setLoading(false);
				}
			}}
			hasLines={false}
			centered
			width={436}
			closable={false}
			destroyOnClose
			okButtonProps={{ disabled: !inputFrom || !inputTo }}
			afterOpenChange={(open) => open && inputFromRef.current?.focus()}
		>
			<h3 className={styles.title}>Замена пломбы</h3>
			<p className={styles.description}>Отсканируйте пломбы грузомест</p>
			<Input
				className={styles.input}
				value={inputFrom}
				onChange={(evt) => setInputFrom(evt.target.value)}
				placeholder="Старая пломба"
				ref={inputFromRef}
				isTransliterate
			/>
			<Input
				className={styles.input}
				value={inputTo}
				onChange={(evt) => setInputTo(evt.target.value)}
				placeholder="Новая пломба"
				isTransliterate
			/>
		</Modal>
	);
};

export default observer(MovingModal);
