import { Modal } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';

import styles from './index.module.scss';

interface IProps {
	title: React.ReactNode;
	totalCounter: number;
	scannedCounter: number;
	description: string;
	children: React.ReactNode;
	footer?: React.ReactNode;
	isShow: boolean;
	onClose?: () => void;
	classModal?: string;
}

const ModalScannedCounter: FC<IProps> = ({
	title,
	totalCounter,
	scannedCounter,
	children,
	description,
	isShow,
	onClose,
	footer,
	classModal,
}) => {
	return (
		<Modal
			className={cn(styles.modal, classModal)}
			width="auto"
			maskClosable
			footer={footer}
			onCancel={onClose}
			title={title}
			centered
			open={isShow}
			destroyOnClose
		>
			<header className={styles.header}>
				<span className={styles.description}>{description}</span>
				<span className={styles.counter}>
					<span className={styles.counter__current}>{scannedCounter || 0}</span>
					<span>/{totalCounter || 0}</span>
				</span>
			</header>
			{children}
		</Modal>
	);
};

export default observer(ModalScannedCounter);
