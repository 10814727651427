import { InputOTP } from 'antd-input-otp';
import { FC, useState } from 'react';

import style from './index.module.scss';

interface IProps {
	label?: string;
	prefixText?: string;
	length: number;
	name?: string;
	onChange: (e: string) => void;
}

const InputTrackNumber: FC<IProps> = ({
	label = 'Введите известные символы трек-номера',
	prefixText = 'BY0800',
	length,
	name,
	onChange,
}) => {
	const [value, setValue] = useState<string[]>([]);

	const handleInputChange = (value: string[]): void => {
		const newValue = value.join('').replace(' ', 'x').split('');
		setValue(newValue);
		onChange(newValue.join('').replaceAll('x', '_'));
	};

	return (
		<div className={style.wrapper}>
			<div className={style.label}>{label}</div>
			<div className={style.prefix__text}>{prefixText}</div>
			<InputOTP
				wrapperClassName={style.wrapper__input}
				inputClassName={style.input}
				length={length}
				value={value}
				onChange={handleInputChange}
				name={name}
			/>
		</div>
	);
};

export default InputTrackNumber;
