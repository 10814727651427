import Button from '@components/buttons/button';
import { useStore } from '@hooks/useStore';
import Icon from '@images/index';
import ShiftService from '@services/shift';
import { Modal as AntModal, ModalProps as AntModalProps } from 'antd';
import cn from 'classnames';
import { FC } from 'react';

import styles from './index.module.scss';

type Props = {
	start: string;
	end: string;
	onClose: () => void;
};

type ModalProps = Omit<AntModalProps, 'children'> & Props;

const ModalCloseShift: FC<ModalProps> = ({ className, open, start, end, onClose, ...props }) => {
	const store = useStore();

	const handleSubmitClick = async (): Promise<void> => {
		try {
			if (store.operator?.is_pos_enabled && store.shift) {
				await ShiftService.closeShift(store.shift?.shift_id);
				onClose();
				store.kassa.openReportModal();
			} else {
				await store.closeShift();
				onClose();
			}
		} catch (err: any) {
			store.notice.setNotice({ message: err?.error || err?.message || '' });
		}
	};

	return (
		<AntModal
			{...props}
			open={open}
			title={<h2 className={styles.title}>Вы точно хотите закрыть смену и завершить рабочий день?</h2>}
			className={cn(className, styles.modal)}
			width={416}
			footer={
				<footer className={styles.footer}>
					<Button onClick={onClose}>Отмена</Button>
					<Button type="primary" onClick={handleSubmitClick}>
						Закрыть смену
					</Button>
				</footer>
			}
			closable={false}
			centered
		>
			<p className={styles.notice}>Закрытие смены приведет к закрытию кассового дня!</p>
			{false && (
				<div className={cn(className, styles.body)}>
					<span className={styles.time}>
						<Icon imageName="Sun" fontSize={24} />
						<span>{start}</span>
					</span>
					<span className={styles.time}>
						<Icon imageName="Moon" fontSize={24} />
						<span>{end}</span>
					</span>
				</div>
			)}
		</AntModal>
	);
};

export default ModalCloseShift;
