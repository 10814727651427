import Button from '@components/buttons/button';
import { useCurrentVisit } from '@hooks/useCurrentVisit';
import { useStore } from '@hooks/useStore';
import Icon from '@images/index';
import OrderService, { IOrderItemsCollectResponse } from '@services/order';
import { Modal, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { FC } from 'react';

import styles from './index.module.scss';

interface IProps {
	data: IOrderItemsCollectResponse | null;
	onClose: (research?: boolean) => void;
}

const ModalWarningCollect: FC<IProps> = ({ data, onClose }) => {
	const store = useStore();
	const visit = useCurrentVisit();

	const columns: ColumnsType<{ name: string; quantity: number }> = [
		{
			dataIndex: 'name',
			title: 'Наименование',
			render: (_, { name }) => <span>{name}</span>,
		},
		{
			dataIndex: 'quantity',
			title: 'Кол-во',
			render: (_, { quantity }) => <span>{quantity}</span>,
		},
	];

	const collectItems = async (): Promise<void> => {
		try {
			store.setLoading(true);
			if (data?.order_market_ids) {
				await OrderService.postOrderItemsCollectWithCorrection({ order_ids: data.order_market_ids });
				visit?.closeModalOrderCollect();
				onClose(true);
			}
		} catch (err: any) {
			store.notice.setNotice({ message: err?.error || err?.message || '' });
		} finally {
			store.setLoading(false);
		}
	};

	return (
		<Modal
			footer={
				<div className={styles.footer}>
					<Button onClick={() => onClose()}>Закрыть</Button>
					<Button onClick={collectItems} type="primary">
						Провести коррецию
					</Button>
				</div>
			}
			width={641}
			closeIcon={false}
			centered
			onCancel={() => onClose()}
			open={!!data?.items?.length}
			title={false}
			destroyOnClose
		>
			<Icon className={styles.icon} fontSize={72} stroke="transparent" color="#A3A3A3" imageName="Info" />
			<div className={styles.title}>
				Отсканированы не все товары из{' '}
				{`заказ${data?.order_market_ids && data?.order_market_ids?.length > 1 ? 'ов' : 'а'}`}{' '}
				{data?.order_market_ids?.join(',')}
			</div>
			<Table dataSource={data?.items?.map((el) => ({ ...el, key: el.name }))} columns={columns} pagination={false} />
		</Modal>
	);
};

export default ModalWarningCollect;
