const getPrinters = (): Promise<Response> =>
	fetch('http://localhost:7000/printers/list', {
		method: 'GET',
	});

//eslint-disable-next-line
const print = async (PdfFile: any, printerPath: string, copies: string): Promise<void> => {
	try {
		const promise = new Promise<void>((res, rej) =>
			setTimeout(() => {
				try {
					res();
				} catch {
					rej();
				}
			}, 100)
		);

		await promise.then(async () => {
			const file = new File([PdfFile], '1.pdf', { type: PdfFile.type });
			const formData = new FormData();
			formData.append('PrinterPath', printerPath);
			formData.append('PdfFile', file);
			formData.append('Copies', `${copies}`);
			await fetch('http://localhost:7000/print/from-pdf', {
				method: 'POST',
				mode: 'no-cors',
				body: formData,
			});
		});
	} catch {
		return Promise.reject();
	}
};

export const PrintService = { getPrinters, print };
