import NoData from '@components/no-data';
import { useStore } from '@hooks/useStore';
import { IItem } from '@store/acceptance/postal/cargo-spaces';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';

import styles from './index.module.scss';

const ItemsTable: FC = () => {
	const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);

	const {
		acceptance: {
			postal: {
				postalCargoSpaces: { modalItems, modalItemsSingle },
			},
		},
	} = useStore();

	if (!modalItems && !modalItemsSingle) return <NoData text="Пакетов нет" />;

	const columns: ColumnsType<IItem> = [
		{
			title: <span>Номер посылки</span>,
			dataIndex: 'code',
			className: styles.cell,
			render: (_, { number, is_deleted }) => (
				<>
					<div>{number}</div>
					{!!is_deleted && <div>Удалено из грузоместа</div>}
				</>
			),
		},
		{
			title: (
				<span>
					{(modalItems?.routing_items && modalItems.routing_items.some((el) => el.store_place_item_full_name)) ||
					(modalItemsSingle && modalItemsSingle.some((el) => el.store_place_item_full_name))
						? 'Номер ячейки'
						: null}
				</span>
			),
			dataIndex: 'cell',
			className: styles.cell,
			render: (_, { store_place_item_full_name }) => <span>{store_place_item_full_name || ''}</span>,
		},
	];

	return (
		<Table
			pagination={false}
			columns={columns}
			dataSource={(modalItems?.routing_items || [])
				//@ts-ignore
				.concat(modalItemsSingle ? modalItemsSingle : [])
				.map((item) => ({ ...item, key: item.id }))}
			onRow={({
				is_scanned: isScan,
				is_deleted: isDeleted,
				store_place_item_full_name,
				message_error: isError,
				status,
			}) => ({
				className: cn(styles.row, {
					[styles.row__check]: isScan || store_place_item_full_name,
					[styles.row__deleted]: isDeleted,
					[styles.row__warning]: isError && status === 2,
					[styles.row__error]: isError && [3, 4, 5].includes(Number(status)),
				}),
			})}
			expandable={{
				expandedRowClassName: ({ message_error: isError, status }) =>
					cn(styles.row, styles.row__expand, {
						[styles.warning]: isError && status === 2,
						[styles.error]: isError && [3, 4, 5].includes(Number(status)),
					}),
				showExpandColumn: false,
				expandedRowRender: ({ message_error }) => <span>{message_error}</span>,
				rowExpandable: ({ message_error, key }) => {
					if (message_error && key && !expandedRowKeys.includes(key)) setExpandedRowKeys((value) => [...value, key]);
					return !!message_error;
				},
				expandedRowKeys,
			}}
			onHeaderRow={() => ({ className: styles.row })}
			className={styles.table}
			locale={{ emptyText: 'Нет данных' }}
		/>
	);
};

export default observer(ItemsTable);
