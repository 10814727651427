import Icon from '@images/index';
import SearchService from '@services/search';
import { ConfigProvider, Input as AntInput, Radio } from 'antd';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { FC, useRef, useState } from 'react';

import Option from './components/option';
import styles from './index.module.scss';

let controller: AbortController;
let timeoutSpinId: any;
let timeoutSearchId: any;

const Search: FC = () => {
	const selectRef = useRef(null);
	const [options, setOptions] = useState<any[]>([]);
	const [value, setValue] = useState<string>('');
	const [error, setError] = useState<string>('');
	const [loading, setLoading] = useState(false);
	const [type, setType] = useState<'Postal' | 'Order'>('Postal');

	const onSearch = async (value: string): Promise<void> => {
		setLoading(false);
		clearTimeout(timeoutSpinId);

		if (value.length < 8) {
			setOptions([]);
			return;
		}

		setLoading(true);

		try {
			if (controller) controller.abort();
			controller = new AbortController();
			const res = await SearchService[`find${type}Extended`](value, controller.signal);

			setOptions(
				type === 'Postal'
					? res.data.map((postal: any) => (
							<Option
								type={postal.type}
								number={postal.number}
								receiverName={postal.receiver_fio}
								receiverPhone={postal.receiver_phone}
								senderName={postal.sender_fio}
								senderPhone={postal.sender_phone}
								key={postal.id}
								id={postal.id}
								selectString={value}
								externalId={postal.external_id}
								agreementTypeId={postal.agreement_type_id}
								status={postal.market_status_name}
								hasButton={postal.market_status_value === 0 && postal.type === 0}
								isJuristic={!!postal.is_juristic}
							/>
					  ))
					: res.data.map((order: any) => (
							<Option
								key={order.id}
								id={order.id}
								type={3}
								number={order.market_id}
								receiverPhone={order.receiver_phone}
								receiverName={order.receiver_fio}
								selectString={value}
								status={order.status_pvz_name}
							/>
					  ))
			);
		} catch (err: any) {
			if (err) {
				if (err.code !== 'ERR_CANCELED') setOptions([]);
				//@ts-ignore
				if (err.message === 'canceled') return;
				setError(err.message || 'Ничего не найдено');
			}
		} finally {
			timeoutSpinId = setTimeout(() => {
				setLoading(false);
				clearTimeout(timeoutSpinId);
			}, 300);
		}
	};

	const handleSearchChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
		const value = evt.target.value.trim();
		setError('');
		setValue(value);
	};

	const handleSearchKeyUp = (evt: React.KeyboardEvent<HTMLInputElement>): void => {
		if (evt.key === 'Enter') {
			clearTimeout(timeoutSearchId);
			timeoutSearchId = setTimeout(() => onSearch(value || ''), 300);
		}
	};

	return (
		<ConfigProvider
			theme={{
				token: {
					borderRadius: 2,
					boxShadowSecondary:
						'0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)',
				},
			}}
		>
			<Radio.Group
				className={styles.group}
				onChange={(evt) => {
					setValue('');
					setOptions([]);
					setType(evt.target.value);
				}}
				value={type}
			>
				<Radio value="Postal">Почта</Radio>
				<Radio value="Order">Заказы</Radio>
			</Radio.Group>
			<div className={styles.label}>
				<AntInput
					minLength={8}
					maxLength={type === 'Order' ? 8 : 20}
					ref={selectRef}
					placeholder="Поиск"
					className={styles.input}
					value={value}
					onChange={handleSearchChange}
					onKeyUp={handleSearchKeyUp}
				/>
				{loading && <Icon className={styles.spin} imageName="Spin" />}
			</div>
			{options.length ? (
				<OverlayScrollbarsComponent className={styles.scroll} options={{ scrollbars: { autoHide: 'move' } }}>
					{options}
				</OverlayScrollbarsComponent>
			) : value ? (
				<p className={styles.error}>{value.length < 8 ? 'Минимум 8 символов' : error || ''}</p>
			) : null}
		</ConfigProvider>
	);
};

export default Search;
