/* eslint-disable @typescript-eslint/no-empty-interface */
import { ImageName } from '@images/index';
import { AcceptanceService } from '@services/acceptance';
import { RootStore } from '@store/index';
import { cast, flow, getRoot, SnapshotIn, types } from 'mobx-state-tree';

import { voiceMessage } from '../../../utils/voiceMessage';

const { string, boolean, optional, number, union, literal, model, maybeNull, array } = types;

const ItemPostal = model('item', {
	id: number,
	status: number,
	status_name: string,
	type: union(literal(0), literal(1)),
	is_scanned: union(literal(0), literal(1)),
	number: maybeNull(string),
	store_external_name: string,
	routing_items_count: number,
	acceptance_date: string,
	active_operator_id: maybeNull(number),
});

const PostalCargoSpacesItem = model('routing-space', {
	count_items: number,
	scanning_count_items: number,
	routing_spaces: array(ItemPostal),
});

const Item = model('item,', {
	id: number,
	number: string,
	is_scanned: union(literal(0), literal(1)),
	is_deleted: union(literal(0), literal(1)),
	message_error: optional(string, ''),
	store_place_item_full_name: maybeNull(string),
	key: maybeNull(number),
	status: maybeNull(number),
});

const ItemsModal = model('modal-items', {
	id: number,
	number: string,
	scanned_count: number,
	count: number,
	routing_items: maybeNull(array(Item)),
});

const ItemCargoSpace = model('item-cargo-space', {
	store_place_item_id: maybeNull(number),
	store_place_item_full_name: maybeNull(string),
	store_place_item_text: maybeNull(string),
	item: model('item-space', {
		id: maybeNull(number),
		number: string,
		message: maybeNull(string),
		status: number,
	}),
});

const PostalCargoSpaces = model('order', {
	postalCargoSpacesItems: maybeNull(PostalCargoSpacesItem),
	modalItems: maybeNull(ItemsModal),
	modalItemsSingle: maybeNull(array(Item)),
	isOpenModalItems: optional(boolean, false),
	infoScannedItem: maybeNull(ItemCargoSpace),
	contentModalInfo: maybeNull(string),
	isNewCell: optional(boolean, false),
})
	.actions((self) => {
		const root = getRoot<typeof RootStore>(self);

		return {
			loadPostalCargoSpaces: flow(function* () {
				root.setLoading(true);
				self.postalCargoSpacesItems = null;
				try {
					const res = yield AcceptanceService.getPostalCargoSpaces();
					self.postalCargoSpacesItems = res.data || null;
				} catch (err: any) {
					root.notice.setNotice({ message: err?.error || err?.message || '' });
				} finally {
					root.setLoading(false);
				}
			}),
			loadItemsForScan: flow(function* (number: string) {
				root.setLoading(true);
				try {
					if (number.includes('BY08')) {
						const res = yield AcceptanceService.postPostalScanNumber(number);
						self.infoScannedItem = res.data;

						if (self.infoScannedItem && self.infoScannedItem.item.status) {
							self.contentModalInfo = self.infoScannedItem?.item.message || '';
							if (self.modalItems?.routing_items?.some((el) => el.number === number)) {
								root.acceptance.postal.postalCargoSpaces.loadItems(self.modalItems?.id);
							}
							localStorage.setItem('voiceMessage', self.infoScannedItem?.store_place_item_text || '');
						}
						voiceMessage(self.infoScannedItem?.store_place_item_text || '');
					} else if (self.infoScannedItem) {
						if (number === 'NewCell') {
							self.isNewCell = true;
							return;
						}
						if (
							self.infoScannedItem.store_place_item_id &&
							Number(number) !== self.infoScannedItem.store_place_item_id &&
							!self.isNewCell
						) {
							if (String(self.infoScannedItem?.store_place_item_id) !== number.replaceAll('0', '')) {
								localStorage.setItem('voiceMessage', 'Неверная ячейка');
								voiceMessage('Неверная ячейка');
								return;
							}
						}

						if (!self.infoScannedItem.store_place_item_full_name && !self.isNewCell) {
							yield AcceptanceService.postStorePlaceItemsScan({
								store_place_item_id_to_block: !self.isNewCell ? null : self.infoScannedItem.store_place_item_id,
								store_place_item_id_to_choose: Number(number),
								item_id: Number(self.infoScannedItem.item.id),
								routing_space_id: Number(self.modalItems?.id),
							});
						}

						if (!self.modalItems?.routing_items?.some((el) => el.number === number)) {
							const item: IItem = {
								number: self.infoScannedItem.item.number,
								is_scanned: 0,
								message_error: [2].includes(self.infoScannedItem.item.status)
									? self.infoScannedItem?.item.message || ''
									: '',
								id: Number(self.infoScannedItem.item.id) || 0,
								store_place_item_full_name: self.infoScannedItem.store_place_item_full_name || '',
								is_deleted: 0,
								status: self.infoScannedItem.item.status,
							};

							if (Array.isArray(self.modalItemsSingle)) {
								self.modalItemsSingle = cast([...self.modalItemsSingle, item]);
							} else {
								self.modalItemsSingle = cast([item]);
							}
						} else {
							root.acceptance.postal.postalCargoSpaces.loadItems(self.modalItems?.id);
						}
						self.isNewCell = false;
						self.contentModalInfo = null;
						localStorage.setItem('voiceMessage', 'Успешно');
						voiceMessage('Успешно');
					} else {
						if (self.modalItems) {
							yield AcceptanceService.postSpacesPauseScanned(self.modalItems.id);
						}
						const res = yield AcceptanceService.postPostalScan({ number });
						root.acceptance.postal.postalCargoSpaces?.loadPostalCargoSpaces();
						self.modalItems = res.data;
					}
				} catch (err: any) {
					root.notice.setNotice({ message: err?.error || err?.message || '' });
				} finally {
					root.setLoading(false);
				}
			}),
			loadItems: flow(function* (id: number | string, status?: number): any {
				root.setLoading(true);
				try {
					if (status && [83, 84].includes(status)) {
						const res = yield AcceptanceService.postSpacesStartScanned(id as number);
						yield root.acceptance.postal.postalCargoSpaces?.loadPostalCargoSpaces();
						self.modalItems = res.data;
						self.isOpenModalItems = true;
					} else {
						const res = yield AcceptanceService.getPostalItems(id as number);
						self.modalItems = res.data;
						self.isOpenModalItems = true;
					}
				} catch (err: any) {
					root.notice.setNotice({ message: err?.error || err?.message || '' });
				} finally {
					root.setLoading(false);
				}
			}),
			setPauseScanned: flow(function* (id: number) {
				yield AcceptanceService.postSpacesPauseScanned(id);
				root.acceptance.postal.postalCargoSpaces?.loadPostalCargoSpaces();
			}),
			setStartScanned: flow(function* (id: number) {
				const res = yield AcceptanceService.postSpacesStartScanned(id as number);
				root.acceptance.postal.postalCargoSpaces?.loadPostalCargoSpaces();
				self.modalItems = res.data;
				self.isOpenModalItems = true;
			}),
			setIsOpenModalItems(value: boolean) {
				if (value === false) {
					self.modalItems = null;
					self.modalItemsSingle = null;
				}
				self.isOpenModalItems = value;
			},
			setCloseInfoModal() {
				self.infoScannedItem = null;
				self.contentModalInfo = null;
			},
		};
	})
	.views(() => {
		return {
			getIconName(value: number): ImageName {
				if ([80, 81].includes(value)) {
					return 'Lock';
				} else if (value === 82) {
					return 'Unlock';
				} else if (value === 83) {
					return 'PauseBgTransparent';
				} else {
					return 'PlayCircle';
				}
			},
		};
	});

export default PostalCargoSpaces;

export interface IItemsModal extends SnapshotIn<typeof ItemsModal> {}
export interface IItem extends SnapshotIn<typeof Item> {}
export interface IItemCargoSpace extends SnapshotIn<typeof ItemCargoSpace> {}

export interface IPostalCargoSpaces extends SnapshotIn<typeof PostalCargoSpaces> {}
export interface IPostalCargoSpacesItem extends SnapshotIn<typeof PostalCargoSpacesItem> {}
export interface IItemPostal extends SnapshotIn<typeof ItemPostal> {}
