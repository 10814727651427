import NoData from '@components/no-data';
import { useScanner } from '@hooks/useScanner';
import { useStore } from '@hooks/useStore';
import { leaveEvent, listenChannel } from '@services/socket';
import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';

import InfoByDriverModal from './components/info-by-driver-modal';
import InfoModal from './components/info-modal';
import SheetByShipmentModal from './components/sheet-by-shipment-modal';
import Table from './components/table';

let timerId: any;

const RouteSheet: FC = () => {
	const {
		acceptance: { routeSheet },
		operator,
		shift,
	} = useStore();

	useScanner(routeSheet.handleScanner);

	useEffect(() => {
		shift &&
			window.Echo &&
			operator?.id &&
			listenChannel(
				({ driver_id: id, owner }) =>
					routeSheet.loadInfoByDriver({ id: +id, isMercenary: owner === 'courier' ? 0 : 1 }),
				'.routing.driver',
				operator?.id
			);

		return () => {
			window.Echo && operator?.id && leaveEvent('.routing.driver', operator.id);
		};
	}, [shift, operator]);

	useEffect(() => {
		clearTimeout(timerId);
		timerId = setTimeout(routeSheet.loadList, 100);
		return () => clearTimeout(timerId);
	}, []);

	return (
		<>
			{routeSheet.list?.length ? <Table /> : <NoData text="Маршрутных листов нет" />}
			<InfoModal />
			<InfoByDriverModal />
			<SheetByShipmentModal />
		</>
	);
};

export default observer(RouteSheet);
