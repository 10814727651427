import Input from '@components/input';
import Modal from '@components/modal';
import { useStore } from '@hooks/useStore';
import PostOfficeSelection from '@pages/main/visits/components/visit/compoments/common/post-office-select';
import { PostalService } from '@services/postal';
import { Radio, RadioChangeEvent } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';

import { replaceNumber } from '../../../../../utils/replaceNumber';
import styles from './index.module.scss';

const ModalOpsChange: FC = () => {
	const { extendedSearch, setLoading, notice, postOffice } = useStore();
	const [phone, setPhone] = useState('');
	const [unp, setUnp] = useState('');
	const [error, setError] = useState('');
	const [type, setType] = useState<'phone' | 'unp'>('phone');
	const [stage, setStage] = useState(1);

	const reset = (): void => {
		setStage(1);
		setError('');
		setPhone('');
		setUnp('');
	};

	const close = (): void => {
		extendedSearch.setDataOpsChange(null);
		reset();
		setType('phone');
	};

	const confirm = (): void => {
		const id = extendedSearch.dataOpsChange?.id;
		if (!id) return;

		setLoading(true);

		if (stage === 1) {
			PostalService.postalCheck(id, type === 'phone' ? { phone_number: phone } : { contractor_unn: unp })
				.then(() => setStage(2))
				.catch((err) =>
					setError(err?.error || err?.message || `Неверный ${type === 'phone' ? 'телефон' : 'УНП'} отправителя`)
				)
				.finally(() => setLoading(false));
			return;
		}

		PostalService.putPostal(id, { store_id_start: postOffice as number })
			.then(() => {
				notice.setNotice({ message: 'Успешно', type: 'success' });
				close();
			})
			.catch((err) => notice.setNotice({ message: err?.error || err?.message || '' }))
			.finally(() => setLoading(false));
	};

	const handleRadioChange = (evt: RadioChangeEvent): void => {
		reset();
		setType(evt.target.value);
	};

	const handlePhoneChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
		setError('');
		setPhone(replaceNumber(evt.target.value));
	};

	const handleUnpChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
		setError('');
		setUnp(replaceNumber(evt.target.value));
	};

	const handleOpsChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
		setError('');
		extendedSearch.dataOpsChange?.setNumber(evt.target.value);
	};

	return (
		<Modal
			classNameModal={styles.modal}
			className={styles.body}
			title={null}
			okText="Продолжить"
			cancelText="Отмена"
			open={!!extendedSearch.dataOpsChange}
			onCancel={close}
			onOk={confirm}
			okButtonProps={{
				disabled:
					(stage === 1
						? type === 'phone'
							? phone.length !== 4
							: !unp || unp.toString().length !== 4
						: !extendedSearch.dataOpsChange?.number || !postOffice) || !!error,
			}}
			hasLines={false}
			centered
			width={416}
			closable={false}
			destroyOnClose
		>
			{stage === 1 ? (
				<>
					<h3 className={styles.title}>Проверки заявки для изменения</h3>
					{extendedSearch.dataOpsChange?.isJuristic && (
						<Radio.Group className={styles.group} onChange={handleRadioChange} value={type}>
							<Radio value="phone">Номер телефона</Radio>
							<Radio value="unp">УНП</Radio>
						</Radio.Group>
					)}
					<p>Введите последние 4 цифры {type === 'phone' ? 'номера телефона' : 'УНП'}</p>
					{type === 'phone' ? (
						<Input className={styles.input} value={phone} onChange={handlePhoneChange} maxLength={4} />
					) : (
						<Input className={styles.input} value={unp} onChange={handleUnpChange} maxLength={4} />
					)}
				</>
			) : (
				<>
					<h3 className={styles.title}>Изменение ОПС отправки в ПчО</h3>
					<Input
						className={cn(styles.input, styles.input__inner)}
						value={extendedSearch.dataOpsChange?.number}
						onChange={handleOpsChange}
						required
						placeholder="Номер ПчО"
						disabled
					/>
					<PostOfficeSelection placeholder="Новое ОПС отправки" value={postOffice} disabled required />
				</>
			)}
			{error && <span className={styles.error}>{error}</span>}
		</Modal>
	);
};

export default observer(ModalOpsChange);
